import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PiStudentDuotone } from "react-icons/pi";

import NoRecord from "components/norecord/NoRecord";
import { getAllStudents } from "redux/actions/ActionCreators/AdminActions";
import AllStudentsTable from "admin/components/students/AllStudentsTable";
import Pagination from "modules/Pagination";
import { StudentModal } from "components/modal/AddStudentModal";
import Search from "modules/Search";
import AnimatedLoading from "components/spinner/AnimatedLoading";

const Students = () => {
  const dispatch = useDispatch();

  const students = useSelector((state) => state?.admin?.students);
  const token = useSelector((state) => state?.auth?.user?.access_token);
  const loading = useSelector((state) => state?.user?.loading);

  const [currentPage, setCurrentPage] = useState(0);
  const [showStudentModal, setShowStudentModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  // Get Students List
  useEffect(() => {
    dispatch(getAllStudents(currentPage + 1, searchQuery, token));
  }, [currentPage]);

  // Set Cuurent Page Number to current - 1 if Next page url is null and students lenght == 10
  useEffect(() => {
    if (
      students?.data?.length == 10 &&
      !students?.next_page_url &&
      currentPage !== 0
    ) {
      setCurrentPage(currentPage - 1);
    }
  }, [students?.data?.length]);

  // Loading
  if (loading) {
    return (
      <div className="flex items-center justify-center h-[calc(100vh-90px)]">
        <AnimatedLoading />
      </div>
    );
  }

  // Handler on Confirm Modal close
  const handleConfirmModal = () => {
    setShowStudentModal(false);
  };

  // Handle Search
  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(getAllStudents(0, searchQuery, token));
  };
  return (
    <div className="min-h-full">
      <h3 className="py-6 text-[20px] lg:text-[28px] font-figtree leading-8 font-bold text-black 2xl:w-[1300px] 2xl:mx-auto">
        Students List
      </h3>
      <div className="bg-white transition rounded-[12px] w-full px-2 md:px-4 lg:px-6 py-4 flex  flex-col  2xl:w-[1300px] 2xl:mx-auto ">
        <div className="flex items-center">
          <PiStudentDuotone size={20} className="text-blueDacker mr-3" />
          <h5 className="text-[16px] leading-6 font-figtree font-medium text-blueDacker">
            Students
          </h5>
        </div>
        {students && (
          <Search
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            handleSearch={handleSearch}
          />
        )}
        {students && students?.data?.length ? (
          <div className="grid grid-cols-1 w-full  pt-4">
            {/* Students Table */}
            <AllStudentsTable
              studentsData={students?.data}
              page={currentPage + 1}
            />
            {/* Pagination component */}
            {students?.total > 10 && (
              <Pagination
                itemsPerPage={students?.per_page}
                totalPages={Math.ceil(students?.total / 10)}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            )}
          </div>
        ) : (
          <NoRecord title={`Sorry, we couldn't find any students data.`} />
        )}
        {/* Add Student Modal */}
        {showStudentModal && (
          <StudentModal onConfirm={handleConfirmModal} page={currentPage + 1} />
        )}
        <div className="flex justify-end items-center">
          <button
            className="relative mx-auto lg:mx-0 mt-3 w-[150px] h-[36px] md:h-[44px] bg-success rounded-[3px] text-white font-figtree font-medium text-centerrelative  inline-flex items-center justify-start inline-block text-[14px]  text-center  px-4  boder-0 outline-none  overflow-hidden  transition-all   hover:bg-white hover:border border-success group"
            onClick={() => setShowStudentModal(true)}
          >
            <span className="absolute inset-0 border-0 group-hover:border-[25px] ease-linear duration-100 transition-all border-white rounded-[6px]"></span>
            <span className="relative w-full text-center text-white transition-colors duration-200 ease-in-out group-hover:text-success">
              Add Student
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Students;
