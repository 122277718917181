import * as ActionTypes from 'redux/actions/ActionTypes/ActionTypes';

let initialState = {
  notificationCount: 0,
};
const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_NEW_USER_NOTIFICATION: {
      return {
        ...state,
        notificationCount: state.notificationCount + 1,
      };
    }
    case ActionTypes.CLEAR_NOTIFICATIONS: {
      return {
        ...state,
        notificationCount: 0,
      };
    }
    default: {
      return state;
    }
  }
};

export default NotificationReducer;
