import React from 'react';
import { ErrorMessage, useField } from 'formik';

const ProfileField = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="form-group  md:my-3 my-2 lg:my-4 xl:my-6">
      {label && (
        <label
          htmlFor={field.name}
          className="block text-dark text-[14px] leading-[20px] font-figtree font-bold"
        >
          {label}
        </label>
      )}
      <input
        className={`w-full xl:w-full 2xl:w-[436px] h-[36px] md:h-[44px] rounded-[6px]  mt-2  p-3 bg-lightgray focus:ring-blue-500 focus:border-blue-500 focus:outline-none     dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
          meta.touched && meta.error && 'border border-red-500 error-form'
        }`}
        {...field}
        {...props}
      />
      <ErrorMessage
        component="p"
        name={field.name}
        className="text-red-500 font-normal font-figtree text-sm mt-2"
      />
    </div>
  );
};

export default ProfileField;
