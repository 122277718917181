import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import DocumentsTable from 'components/dashboard/DocumentsTable';
import NoRecord from 'components/norecord/NoRecord';
import DocsIcon from 'assets/images/upload-docs.png';

const ProfileDocuments = () => {
  const documents = useSelector((state) => state?.user?.documents);
  const navigate = useNavigate();

  return (
    <div className="bg-white transition rounded-[12px] w-full px-2 md:px-4 lg:px-6 py-4 flex  flex-col  2xl:w-[1300px] 2xl:mx-auto ">
      <div className="flex items-center px-2 md:px-0">
        <img
          src={DocsIcon}
          loading="lazy"
          alt="icon"
          className="h-[16px] w-[16px] mr-3"
        />
        <h5 className="text-[16px] leading-6 font-figtree font-medium text-blueDacker">
          Uploaded Documents
        </h5>
      </div>
      {documents.length ? (
        <div className=" grid grid-cols-1 w-full pt-4">
          <DocumentsTable documentsData={documents} />
        </div>
      ) : (
        <NoRecord title={`Sorry, we couldn't find any documents data.`} />
      )}
      <div className="flex justify-end items-center">
        <button
          className="relative mx-auto lg:mx-0 mt-3 w-[150px] h-[36px] md:h-[44px] bg-success rounded-[3px] text-white font-figtree font-medium text-centerrelative  inline-flex items-center justify-start inline-block text-[14px]  text-center  px-4  boder-0 outline-none  overflow-hidden  transition-all   hover:bg-white hover:border border-success group"
          onClick={() => navigate('/upload-documents')}
        >
          <span className="absolute inset-0 border-0 group-hover:border-[25px] ease-linear duration-100 transition-all border-white rounded-[6px]"></span>
          <span className="relative w-full text-center text-white transition-colors duration-200 ease-in-out group-hover:text-success">
            Upload Documets
          </span>
        </button>
      </div>
    </div>
  );
};

export default ProfileDocuments;
