import React from "react";
import { useSelector } from "react-redux";

import AdminRoutes from "routes/AdminRoutes";
import UserRoutes from "routes/UserRoutes";

const AuthenticatedRoutes = () => {
  const role = useSelector((state) => state?.auth?.user?.role);
  return <>{role === "admin" ? <AdminRoutes /> : <UserRoutes />}</>;
};

export default AuthenticatedRoutes;
