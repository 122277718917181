import axios from 'axios';
import { toast } from 'react-toastify';

import * as ActionTypes from 'redux/actions/ActionTypes/ActionTypes';
import { setLoadingFalse, setLoadingTrue } from './LoadingActions';
import { config } from 'helpers/config/Config';

let backendUrl = `${process.env.REACT_APP_BACKEND_URL}`;

// Register  User
export const registerUser = (formData, navigate) => async (dispatch) => {
  try {
    dispatch(setLoadingTrue());
    let response = await axios.post(`${backendUrl}/register`, formData);
    dispatch({
      type: ActionTypes.REGISTER_USER,
      payload: response?.data,
    });
    navigate('/dashboard');
    dispatch(setLoadingFalse());
  } catch (error) {
    console.log(error);
    dispatch(setLoadingFalse());
    toast.error(error.response?.data.message || error?.message);
    dispatch({
      type: ActionTypes.SET_ERROR,
      payload: error.data?.message,
    });
  }
};

// Login  User
export const loginUser = (formData, navigate) => async (dispatch) => {
  try {
    dispatch(setLoadingTrue());
    let response = await axios.post(`${backendUrl}/login`, formData);
    dispatch({
      type: ActionTypes.LOGIN_USER,
      payload: response?.data,
    });
    navigate('/dashboard');
    dispatch(setLoadingFalse());
  } catch (error) {
    console.log(error);
    dispatch(setLoadingFalse());
    toast.error(error.response?.data.message || error?.message);
    dispatch({
      type: ActionTypes.SET_ERROR,
      payload: error?.message,
    });
  }
};

// Google Login
export const loginWithGoogle = (formData, navigate) => async (dispatch) => {
  try {
    dispatch(setLoadingTrue());
    let response = await axios.post(
      `${backendUrl}/auth/google/callback`,
      formData
    );
    dispatch({
      type: ActionTypes.REGISTER_USER,
      payload: response?.data,
    });
    navigate('/dashboard');
    dispatch(setLoadingFalse());
  } catch (error) {
    console.log(error);
    dispatch(setLoadingFalse());
    toast.error(error.response?.data.message || error?.message);
    dispatch({
      type: ActionTypes.SET_ERROR,
      payload: error.data?.message,
    });
  }
};

// Logout User
export const logoutUser = () => (dispatch) => {
  dispatch({
    type: ActionTypes.LOGOUT_USER,
  });
};

// Forgot Password
export const forgotPassword = (email, navigate) => async (dispatch) => {
  try {
    dispatch(setLoadingTrue());
    let response = await axios.post(`${backendUrl}/forgot-password`, email);
    toast.success(response?.data?.message);
    navigate('/reset-password');
    dispatch(setLoadingFalse());
  } catch (error) {
    console.log(error);
    dispatch(setLoadingFalse());
    toast.error(error.response?.data.message || error?.message);
  }
};

// Reset Password
export const resetPassword = (formData, navigate) => async (dispatch) => {
  try {
    dispatch(setLoadingTrue());
    const response = await axios.post(`${backendUrl}/reset-password`, formData);
    toast.success(response?.data?.message);
    dispatch(setLoadingFalse());
    navigate('/login');
  } catch (error) {
    console.log(error);
    dispatch(setLoadingFalse());
    toast.error(error.response?.data.message || error?.message);
    dispatch(setLoadingFalse());
  }
};

// Change User Password
export const changePassword = (formData, token) => async (dispatch) => {
  try {
    dispatch(setLoadingTrue());
    const response = await axios.post(
      `${backendUrl}/change-password`,
      formData,
      config(token)
    );
    toast.success(response?.data);
    dispatch(setLoadingFalse());
  } catch (error) {
    console.log(error);
    dispatch(setLoadingFalse());
    toast.error(error.response?.data.message || error?.message);
    dispatch(setLoadingFalse());
  }
};
