import React from "react";
import Sidebar from "components/sidebar/Sidebar";
import TopNav from "components/topnav/TopNav";

const DashboardLayout = (props) => {
  return (
    <div className="w-full flex transition">
      <Sidebar />
      <div className="flex-1">
        <TopNav />
        <div className="bg-[#f2f2f2] px-4 transition min-h-[calc(100vh-70px)] pb-4">
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
