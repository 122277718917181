import * as ActionTypes from '../actions/ActionTypes/ActionTypes';

let initialState = {
  loading: false,
  students: [],
  error: null,
  appointments: [],
  allAppointments: [],
  allStudents: [],
  analytics: {},
  applications: [],
};

const AdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_STUDENTS: {
      return {
        ...state,
        students: action.payload,
      };
    }
    case ActionTypes.GET_ALL_STUDENTS_DATA: {
      return {
        ...state,
        allStudents: action.payload,
      };
    }
    case ActionTypes.ADMIN_GET_ALL_STUDENTS_APPOINTMENTS: {
      return {
        ...state,
        appointments: action.payload,
      };
    }
    case ActionTypes.GET_ALL_APPOINTMENTS_DATA: {
      return {
        ...state,
        allAppointments: action.payload,
      };
    }
    case ActionTypes.FILTER_APPOINTMENTS: {
      return {
        ...state,
        appointments: action.payload,
      };
    }
    case ActionTypes.FILTER_STUDENTS: {
      return {
        ...state,
        students: action.payload,
      };
    }
    case ActionTypes.FILTER_APPLICATIONS: {
      return {
        ...state,
        applications: action.payload,
      };
    }
    case ActionTypes.SET_LOADING_TRUE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.SET_LOADING_FALSE: {
      return {
        ...state,
        loading: false,
      };
    }
    case ActionTypes.ADMIN_GET_ANALYTICS: {
      return {
        ...state,
        analytics: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default AdminReducer;
