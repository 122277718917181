import { ErrorMessage, useField } from 'formik';

const DocumentsField = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="block  md:mt-3 mt-4 lg:mt-4 xl:mt-6">
      {label && (
        <label
          htmlFor={field.name}
          className="block text-dark text-[14px] leading-[20px] font-figtree font-medium"
        >
          {label}
        </label>
      )}
      <input
        className={`w-full lg:w-[400px] 2xl:w-[436px] h-[48px] rounded-[6px]  mt-2  p-3 bg-white border border-lightgray focus:ring-blue-500 focus:border-blue-500 focus:outline-none     dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
          meta.touched && meta.error && 'border border-red-500 error-form'
        }`}
        type="file"
        {...field}
        {...props}
      />
      <ErrorMessage
        component="p"
        name={field.name}
        className="text-red-500 font-normal font-figtree  text-sm mt-2"
      />
    </div>
  );
};

export default DocumentsField;
