import * as ActionTypes from "../actions/ActionTypes/ActionTypes";

let initialState = {
  loading: false,
  user_info: null,
  error: null,
  updateUser: false,
  appointments: [],
  documents: [],
  applications: [],
  profilePic: null,
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_USER_INFO: {
      return {
        ...state,
        user_info: action.payload,
      };
    }
    case ActionTypes.USER_PROFILE_IMG: {
      return {
        ...state,
        profilePic: action.payload,
      };
    }
    case ActionTypes.EMPTY_PROFILE_IMG: {
      return {
        ...state,
        profilePic: null,
      };
    }
    case ActionTypes.GET_USER_APPOINTMENTS: {
      return {
        ...state,
        appointments: action.payload,
      };
    }
    case ActionTypes.GET_USER_DOCUMENTS: {
      return {
        ...state,
        documents: action.payload,
      };
    }
    case ActionTypes.GET_USER_APPLICATIONS: {
      return {
        ...state,
        applications: action.payload,
      };
    }
    case ActionTypes.SET_LOADING_TRUE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionTypes.SET_LOADING_FALSE: {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default UserReducer;
