import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Logo from 'assets/images/logo.png';
import TextField from 'components/textfield/TextField';
import Spinner from 'components/spinner/Spinner';
import { registerUser } from 'redux/actions/ActionCreators/AuthActions';
import GoogleLoginPage from './GoogleLogin';

const SignUp = () => {
  const loading = useSelector(state => state?.auth?.loading);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Sign Up Form Initial Values
  const initialValues = {
    name: '',
    email: '',
    phone: '',
    password: ''
  };
  // Error Schema
  const errorSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    phone: Yup.string().required('Phone Number is required'),
    email: Yup.string().email().required('Email is required'),
    password: Yup.string().required('Password  is required')
  });
  // Form Submission
  const handleSubmit = (values, { resetForm }) => {
    let data = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      password: values.password
    };
    dispatch(registerUser(data, navigate));
    // resetForm({
    //   values: '',
    // });
  };
  return (
    <div className="flex items-center flex-col rounded-[12px] bg-[#ffffff] lg:my-[30px] xl:my-0 px-6  md:px-6 lg:px-8 py-6 2xl:max-h-[800px] 2xl:w-[500px] ">
      <img
        src={Logo}
        loading="lazy"
        alt="logo"
        className=" w-[130px] md:w-[150px] h-full mb-2 lg:mb-3 xl:mb-5 lg:w-[146px] lg:h-[24px]"
      />
      <h3 className=" text-[20px] md:text-[25px] lg:text-[28px] leading-[30px] lg:leading-[32px] font-jakarta-sans text-dark font-bold mb-2 lg:mb-2">
        Create an account
      </h3>
      <p className="text-[14px] leading-[20px] font-jakarta-sans font-medium text-dark">Start your dream journey!</p>
      <Formik initialValues={initialValues} validationSchema={errorSchema} onSubmit={handleSubmit}>
        {formik => (
          <Form className="w-full" autoComplete="off">
            <TextField type="text" label="Full Name" name="name" placeholder="Enter your name" />
            <TextField type="email" label="Email Address" name="email" placeholder="Enter your email" />
            <TextField type="text" label="Phone Number" name="phone" placeholder="Enter your phone number" />
            <TextField type="password" label="Password" name="password" placeholder="Enter your password" />
            {/* Sign In Button  */}
            <button className="relative  inline-flex items-center justify-start inline-block  text-white text-[14px] font-jakarta-sans font-medium w-full xl:w-full 2xl:w-[436px] h-[44px] text-center  px-4  boder-0 outline-none  overflow-hidden  transition-all bg-success rounded-[6px] hover:bg-white hover:border border-success group">
              <span className="absolute inset-0 border-0 group-hover:border-[25px] ease-linear duration-100 transition-all border-white rounded-[6px]"></span>
              <span className="relative w-full text-center text-white transition-colors duration-200 ease-in-out group-hover:text-success">
                {loading ? <Spinner /> : 'Get Started'}
              </span>
            </button>
          </Form>
        )}
      </Formik>
      <GoogleLoginPage />
      {/* Don't Have an Acc */}
      <p className="text-dark font-jakarta-sans font-light text-[14px] text-clip">
        Already have an account?
        <span className="text-primary cursor-pointer font-medium" onClick={() => navigate('/login')}>
          Login
        </span>
      </p>
    </div>
  );
};

export default SignUp;
