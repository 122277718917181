import React from 'react';
import UserImg from 'assets/images/user.png';
import Spinner from 'components/spinner/Spinner';

const AdminChatSidebar = (props) => {
  const {
    loading,
    recentChats,
    selectedChat,
    setSelected,
    setSelectedChat,
    setSearchText,
    searchText,
  } = props;

  return (
    <div className="col-span-5 md:col-span-4 lg:col-span-3 border-r border-r-lightgray h-full overflow-y-auto custom-scroll-bar ">
      <form className="flex items-center bg-lightgray">
        <input
          className="w-full px-2 py-2 border-0 bg-transparent rounded outline-none"
          placeholder="Search Users"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </form>
      <div className="mb-8">
        <p className="px-3 py-2 text-[16px] font-figtree font-medium text-success">
          Recent Chats
        </p>
        {loading ? (
          <div className="flex justify-center items-center flex-col">
            <Spinner />
          </div>
        ) : recentChats.length ? (
          recentChats.map((data, index) => (
            <div
              className={`flex cursor-pointer items-center px-2 py-1 border-b border-b-lightgray ${
                selectedChat == data?.id ? 'bg-success' : 'bg-white'
              }`}
              key={index}
              onClick={() => (setSelectedChat(data?.id), setSelected(true))}
            >
              <img src={UserImg} className="w-[50px]" alt="user-img" />
              <h5 className="text-[16px] font-figtree font-normal">
                {data?.name}
              </h5>
            </div>
          ))
        ) : (
          <div>
            <p className="text-[14px] text-center mt-4 text-dark font-figtree fonr-normal ">
              No Recent Conversations Found
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminChatSidebar;
