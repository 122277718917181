import React from 'react';
import { BsCloudUpload } from 'react-icons/bs';

import DocumentsTabs from 'components/applications/DocumentsTabs';

const UploadDocumets = () => {
  return (
    <div>
      <h3 className=" py-6 text-[20px] capitalize lg:text-[28px] font-figtree leading-8 font-bold text-black 2xl:w-[1300px] 2xl:mx-auto">
        Upload Documents
      </h3>
      <div className="bg-white rounded-[12px] h-[101px] w-full flex justify-center flex-col px-4 2xl:w-[1300px] 2xl:mx-auto transition">
        <div className="flex items-center">
          <h3 className=" text-[16px] lg:text-[22px] font-medium font-figtree text-blueDacker">
            Upload Documents
          </h3>
          <BsCloudUpload size={25} className="text-success ml-3" />
        </div>
        <p className="text-[14px] lg:text-[16px] font-normal font-figtree text-dark">
          Upload the required documents and select their respective fields in
          the dropdown.
        </p>
      </div>
      <DocumentsTabs />
    </div>
  );
};

export default UploadDocumets;
