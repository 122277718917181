import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import CalenderComponent from "admin/components/dashboard/CalenderComponent";
import DashboardBanner from "admin/components/dashboard/DashboardBanner";
import {
  admingetAllAppointments,
  adminGetAnalytics,
} from "redux/actions/ActionCreators/AdminActions";
import AnimatedLoading from "components/spinner/AnimatedLoading";

const AdminDashboard = () => {
  const dispatch = useDispatch();

  const token = useSelector((state) => state?.auth?.user?.access_token);
  const loading = useSelector((state) => state?.user?.loading);

  // Get All Appointments and analytics data
  useEffect(() => {
    dispatch(admingetAllAppointments(token));
    dispatch(adminGetAnalytics(token));
  }, []);

  // Loading
  if (loading) {
    return (
      <div className="flex items-center justify-center h-[calc(100vh-90px)]">
        <AnimatedLoading />
      </div>
    );
  }
  return (
    <div className="min-h-full">
      <h3 className="py-6 2xl:w-[1300px] 2xl:mx-auto text-[20px] lg:text-[28px] font-figtree leading-8 font-bold text-black">
        Welcome! Admin
      </h3>
      <div className="grid grid-cols-1   gap-4 ">
        <div className="  ">
          <DashboardBanner />
        </div>
        <div className="">
          <div className="bg-white w-full lg:h-[full] 2xl:w-[1300px] 2xl:mx-auto transition  rounded-[16px]">
            <CalenderComponent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
