import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';

import AppointmentsCard from 'components/dashboard/AppointmentsCard';
import DocumentsCard from 'components/dashboard/DocumentsCard';
import ProfileCard from 'components/dashboard/ProfileCard';
import {
  getAppointments,
  getUserdocuments,
  getUserInfo,
} from 'redux/actions/ActionCreators/UserActions';
import OffersCrad from 'components/dashboard/OffersCrad';
import TodaysMeetingCard from 'components/dashboard/TodaysMeetingCard';
import { config } from 'helpers/config/Config';
import AnimatedLoading from 'components/spinner/AnimatedLoading';

const Dashboard = () => {
  const dispatch = useDispatch();

  const token = useSelector((state) => state?.auth?.user?.access_token);
  const loading = useSelector((state) => state?.user?.loading);
  const user = useSelector((state) => state?.user?.user_info?.user[0]);

  const [latestOffersData, setLatestOffersData] = useState([]);
  const [todaysAppointmentData, setTodaysAppointmentData] = useState(null);
  const [dataLoading, setLoading] = useState(false);

  // Load UserInfo, Appointments and Douments Data
  useEffect(() => {
    dispatch(getUserInfo(token));
    dispatch(getAppointments(1, token));
    dispatch(getUserdocuments(token));
    getTodayAppointmentData(token);
    getLatestOffers(token);
  }, []);

  // Function to get latest offers data
  const getLatestOffers = async (token) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/user/recent-offer-letter`,
        config(token)
      );
      setLatestOffersData(response?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  // Function to get Today's Appointment
  const getTodayAppointmentData = async (token) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/user/appointments/today`,
        config(token)
      );
      if (!response?.data?.original?.message) {
        setTodaysAppointmentData(response?.data?.original?.appointments[0]);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  // Loading
  if (loading || dataLoading) {
    return (
      <div className="flex items-center justify-center h-[calc(100vh-90px)]">
        <AnimatedLoading />
      </div>
    );
  }
  return (
    <div className="">
      <h3 className=" py-6 text-[20px] capitalize lg:text-[28px] font-figtree leading-8 font-bold text-black 2xl:w-[1350px] 2xl:mx-auto">
        Welcome! {user?.name}
      </h3>
      <div className=" grid grid-cols-12 2xl:flex 2xl:justify-center mb-4 gap-2 transition">
        <div className="col-span-12 lg:col-span-3  transition">
          <ProfileCard />
        </div>
        <div className="col-span-12 lg:col-span-5 transition">
          <DocumentsCard />
        </div>
        <div className="col-span-12 lg:col-span-4  transition">
          <OffersCrad latestOffersData={latestOffersData} />
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4 2xl:gap-3  2xl:flex 2xl:justify-center transition">
        <div className="col-span-12 lg:col-span-8 2xl:w-[980px] transition">
          <AppointmentsCard />
        </div>
        <div className="col-span-12 lg:col-span-4  2xl:w-[376px] transition">
          <TodaysMeetingCard todaysAppointmentData={todaysAppointmentData} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
