import React, { useEffect, useRef, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { GrClose } from "react-icons/gr";
import { FiLogOut } from "react-icons/fi";
import { CgProfile } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { BsFillCloudUploadFill } from "react-icons/bs";
import { BiSolidBell } from "react-icons/bi";
import axios from "axios";

import Logo from "assets/images/logo.png";
import UserImg from "assets/images/user.png";
import { logoutUser } from "redux/actions/ActionCreators/AuthActions";
import "components/sidebar/sidebar.css";
import UserNotifications from "./UserNotifications";
import { config } from "helpers/config/Config";
import { toast } from "react-toastify";

const TopNav = () => {
  const navigate = useNavigate();
  const clickRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();

  const role = useSelector((state) => state?.auth?.user?.role);

  const profileImg = useSelector((state) => state?.user?.profilePic);
  const userName = useSelector(
    (state) => state?.user?.user_info?.user[0]?.name
  );
  const isAdmin = role === "admin" ? true : false;
  const [active, setActive] = useState(false);
  const [mobileSideBar, setMobileSidebar] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [noticationCount, setNotificationCount] = useState(0);
  const [userNotificationsData, setUserNotificationsData] = useState([]);
  const [reRenderNotifications, setRerenderNotifications] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state?.auth?.user?.access_token);

  // Detect outside Clcik
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  }, []);
  const handleClickOutside = (e) => {
    if (clickRef.current && !clickRef.current.contains(e.target)) {
      setActive(false);
    } else {
      return;
    }
  };

  // Function to Load User Notifications
  const getUserNotifications = async (token) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/user/notication`,
        config(token)
      );
      setUserNotificationsData(response?.data);
      setNotificationCount(response?.data?.unread_count);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error(error.response?.data.message || error?.message);
    }
  };

  // Call GetNotification On Route Chnage
  useEffect(() => {
    getUserNotifications(token);
  }, [location?.pathname]);

  // Call GetNotification On when user clear notifications data
  useEffect(() => {
    if (reRenderNotifications) {
      getUserNotifications(token);
      setRerenderNotifications(false);
    }
  }, [reRenderNotifications]);

  return (
    <div className=" h-[70px] bg-white relative w-full flex items-center justify-center md:justify-end pr-8 border-b border-[#E2E2EA]">
      <div className="mr-4 relative flex items-center">
        {!isAdmin && (
          <BiSolidBell
            size={30}
            className="text-gray cursor-pointer"
            onClick={() => (
              setShowNotifications(!showNotifications), setActive(false)
            )}
          />
        )}

        {noticationCount > 0 && (
          <p className="absolute bg-red-500 w-[20px] h-[20px]  text-[12px] flex items-center justify-center  text-white top-[-8px] rounded-full right-[-8px] z-10">
            {noticationCount > 99 ? "99+" : noticationCount}
          </p>
        )}

        {showNotifications &&
          (isAdmin ? (
            ""
          ) : (
            <UserNotifications
              setShowNotifications={setShowNotifications}
              notificationsData={userNotificationsData}
              setRerenderNotifications={setRerenderNotifications}
            />
          ))}
      </div>
      <div
        ref={clickRef}
        className="dropdown  relative z-[999px] "
        onClick={() => (setActive(!active), setShowNotifications(false))}
      >
        <div className="flex items-center group cursor-pointer">
          <img
            src={
              !isAdmin && profileImg != null
                ? profileImg?.base64_encoded_document
                : UserImg
            }
            alt="profile"
            loading="lazy"
            className="h-[32px] w-[32px] rounded-full"
          />
          <h3 className="text-[14px] ml-2 capitalize text-black font-poppins font-semibold leading-3">
            {role === "admin" ? "Admin" : userName}
          </h3>
          <svg
            width="11"
            height="7"
            viewBox="0 0 11 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.95969 0C10.3789 0 10.612 0.484966 10.3501 0.812347L6.0571 6.17862C5.85694 6.42883 5.47639 6.42883 5.27623 6.17862L0.983211 0.812347C0.721305 0.484965 0.954392 0 1.37365 0L9.95969 0Z"
              fill="#92929D"
            />
          </svg>
        </div>
        <ul
          className={`dropdown-menu absolute right-[-20px] ${
            active ? "block" : "hidden"
          }  text-gray-700  w-[200px]  bg-white shadow-md z-10 rounded-md mt-6 `}
        >
          {!isAdmin && (
            <li
              className="flex items-center border-b border-b-lightgray py-4 px-4 hover:bg-lightgray cursor-pointer"
              onClick={() => navigate("/change-password")}
            >
              <FiLogOut size={20} className="text-dark " />
              <p className="rounded-b text-dark text-[14px] font-figtree font-normal ml-2  block whitespace-no-wrap">
                Change Password
              </p>
            </li>
          )}
          <li
            className="flex items-center py-4 px-4 hover:bg-lightgray cursor-pointer"
            onClick={() => dispatch(logoutUser())}
          >
            <FiLogOut size={20} className="text-dark " />
            <p className="rounded-b text-dark text-[14px] font-figtree font-normal ml-2  block whitespace-no-wrap">
              Logout
            </p>
          </li>
        </ul>
      </div>
      <div className="ml-3 md:hidden absolute right-2">
        <AiOutlineMenu
          size={25}
          className="cursor-pointer"
          onClick={() => setMobileSidebar(true)}
        />
      </div>
      {/* Mobile Navbar */}
      <div
        className={`mobile-sidebar block md:hidden ${
          mobileSideBar ? "menu-active fixed" : ""
        }`}
      >
        <GrClose
          size={25}
          className="absolute top-4 cursor-pointer  right-3"
          onClick={() => setMobileSidebar(false)}
        />
        <div className="flex items-center px-6 py-4">
          <img
            src={Logo}
            loading="lazy"
            alt="logo"
            className="h-[30px] w-[150px]"
          />
        </div>
        <div className="py-4 px-6 ">
          {/* Nav Item Dashboard */}
          <NavLink
            to={isAdmin ? "/admin-dashboard" : "/dashboard"}
            className="flex items-center py-2 group cursor-pointer"
            onClick={() => setMobileSidebar(false)}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 10H5C7.20914 10 9 11.7909 9 14V16C9 18.2091 7.20914 20 5 20H4C1.79086 20 0 18.2091 0 16V14C0 11.7909 1.79086 10 4 10ZM4 12C2.89543 12 2 12.8954 2 14V16C2 17.1046 2.89543 18 4 18H5C6.10457 18 7 17.1046 7 16V14C7 12.8954 6.10457 12 5 12H4ZM3.5 0C5.433 0 7 1.567 7 3.5V4.5C7 6.433 5.433 8 3.5 8C1.567 8 0 6.433 0 4.5V3.5C0 1.567 1.567 0 3.5 0ZM3.5 2C2.67157 2 2 2.67157 2 3.5V4.5C2 5.32843 2.67157 6 3.5 6C4.32843 6 5 5.32843 5 4.5V3.5C5 2.67157 4.32843 2 3.5 2ZM15 9H16C18.2091 9 20 10.7909 20 13V16C20 18.2091 18.2091 20 16 20H15C12.7909 20 11 18.2091 11 16V13C11 10.7909 12.7909 9 15 9ZM15 11C13.8954 11 13 11.8954 13 13V16C13 17.1046 13.8954 18 15 18H16C17.1046 18 18 17.1046 18 16V13C18 11.8954 17.1046 11 16 11H15ZM12.5 0H16.5C18.433 0 20 1.567 20 3.5C20 5.433 18.433 7 16.5 7H12.5C10.567 7 9 5.433 9 3.5C9 1.567 10.567 0 12.5 0ZM12.5 2C11.6716 2 11 2.67157 11 3.5C11 4.32843 11.6716 5 12.5 5H16.5C17.3284 5 18 4.32843 18 3.5C18 2.67157 17.3284 2 16.5 2H12.5Z"
                fill="#92929D"
                className="group-hover:fill-success"
              />
            </svg>
            <h5 className="ml-3 text-[14px] font-medium text-black leading-5 font-figtree group-hover:text-success group-hover:font-bold">
              Dashboard
            </h5>
          </NavLink>
          {/* Nav Item Profile */}
          <NavLink
            to={isAdmin ? "/students" : "/profile"}
            className="flex items-center py-2 group cursor-pointer"
            onClick={() => setMobileSidebar(false)}
          >
            <CgProfile
              size={22}
              className="text-[#92929D] group-hover:text-success"
            />
            <h5 className="ml-3 text-[14px] font-medium text-black leading-5 font-figtree group-hover:text-success group-hover:font-bold">
              {isAdmin ? "Students" : " Profile"}
            </h5>
          </NavLink>
          {/* Nav Item Appointments */}
          <NavLink
            to={isAdmin ? "/admin-appointments" : "/appointments"}
            className="flex items-center py-2 group cursor-pointer"
            onClick={() => setMobileSidebar(false)}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.3 11.7V8.3H2L2 11.7H6.3ZM8.3 11.7L18 11.7V8.3L8.3 8.3V11.7ZM6.3 2H3C2.44772 2 2 2.44772 2 3V6.3H6.3V2ZM8.3 2L8.3 6.3L18 6.3V3C18 2.44772 17.5523 2 17 2H8.3ZM6.3 18V13.7H2L2 17C2 17.5523 2.44772 18 3 18H6.3ZM8.3 18H17C17.5523 18 18 17.5523 18 17V13.7H8.3V18ZM0 17L0 3C0 1.34315 1.34315 0 3 0H17C18.6569 0 20 1.34315 20 3V17C20 18.6569 18.6569 20 17 20H3C1.34315 20 0 18.6569 0 17Z"
                fill="#92929D"
                className="group-hover:fill-success"
              />
            </svg>

            <h5 className="ml-3 text-[14px] font-medium text-black leading-5 font-figtree group-hover:text-success group-hover:font-bold">
              Appointments
            </h5>
          </NavLink>
          {/* Nav Item Application */}
          <NavLink
            to={isAdmin ? "/admin-applications" : "/applications"}
            className="flex items-center py-2 group cursor-pointer"
            onClick={() => setMobileSidebar(false)}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 0H12C13.1046 0 14 0.89543 14 2V9H18C19.1046 9 20 9.89543 20 11V19C20 19.5523 19.5523 20 19 20H13H7H1C0.447715 20 0 19.5523 0 19V6.5C0 5.39543 0.89543 4.5 2 4.5H6V2C6 0.89543 6.89543 0 8 0ZM8 6.5V18H12V11V2H8V6.5ZM6 6.5H2V18H6V6.5ZM14 18H18V11H14V18Z"
                fill="#92929D"
                className="group-hover:fill-success"
              />
            </svg>

            <h5 className="ml-3 text-[14px] font-medium text-black leading-5 font-figtree group-hover:text-success group-hover:font-bold">
              Applications
            </h5>
          </NavLink>
          {/* Nav Item Upload Documents */}
          {!isAdmin && (
            <NavLink
              to="upload-documents"
              className="flex items-center py-2 group cursor-pointer"
              onClick={() => setMobileSidebar(false)}
            >
              <BsFillCloudUploadFill
                size={20}
                className="text-[#92929D] group-hover:text-success"
              />
              <h5 className="ml-3 text-[14px] font-medium text-black leading-5 font-figtree group-hover:text-success group-hover:font-bold">
                Upload Documents
              </h5>
            </NavLink>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopNav;
