import React from "react";
import { useSelector } from "react-redux";

import Logo from "assets/images/logo.png";
import AdminLinks from "./AdminLinks";
import "./sidebar.css";
import UserLinks from "./UserLinks";

const Sidebar = () => {
  const role = useSelector((state) => state?.auth?.user?.role);

  return (
    <div className="hidden md:block  w-[250px] h-[100vh] bg-white border-r border-[#E2E2EA] transition">
      <div className="flex h-[69px] flex-col items-center justify-center">
        <img
          src={Logo}
          loading="lazy"
          alt="logo"
          className=" h-[30px] w-[182.5px]"
        />
      </div>
      <svg
        width="250"
        height="1"
        viewBox="0 0 250 1"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="250" height="1" fill="#E2E2EA" />
      </svg>
      <div className="py-4 flex flex-col px-[35px]">
        <h5 className="text-gray mt-4 text-[14px] font-bold leading-5 font-figtree">
          MAIN
        </h5>
        {role === "admin" ? <AdminLinks /> : <UserLinks />}
      </div>
    </div>
  );
};

export default Sidebar;
