import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import DocsIcon from 'assets/images/upload-docs.png';
import NoRecord from 'components/norecord/NoRecord';
import DocumentsTable from './DocumentsTable';

const DocumentsCard = () => {
  const navigate = useNavigate();
  const documents = useSelector((state) => state?.user?.documents);

  return (
    <div className="transition px-2 md:px-4 lg:px-6 py-6 flex flex-col w-full lg:relative bg-white 2xl:w-[600px] lg:h-[350px] rounded-[12px]">
      <div className="flex items-center">
        <img
          src={DocsIcon}
          loading="lazy"
          alt="icon"
          className="h-[16px] w-[16px] mr-3"
        />
        <h5 className="text-[16px] leading-6 font-figtree font-medium text-blueDacker">
          Uploaded Documents
        </h5>
      </div>
      {documents.length ? (
        <div className="overflow-y-scroll custom-scroll-bar overflow-x-hidden pt-4">
          {/* Documents Table  will show only 4 documents in dashboard*/}
          <DocumentsTable documentsData={documents.slice(0, 3)} />
        </div>
      ) : (
        <NoRecord title={`Sorry, we couldn't find any documents data.`} />
      )}
      {documents.length ? (
        <p
          className="text-[12px] text-primary mt-3 lg:absolute lg:bottom-5 lg:right-4 text-right cursor-pointer"
          onClick={() => navigate('/profile', { state: true })}
        >
          Click Here To View All Documents
        </p>
      ) : (
        ''
      )}
    </div>
  );
};

export default DocumentsCard;
