import React from 'react';

import StudentImg from 'assets/images/admin-dash.png';
import { useSelector } from 'react-redux';

const DashboardBanner = () => {
  const analyticsData = useSelector((state) => state?.admin?.analytics);
  const studentsPercentage = analyticsData?.user_percentage_change
    ? analyticsData?.user_percentage_change
    : 0;
  const appointmentsPercentage = analyticsData?.appoinment_percentage_change
    ? analyticsData?.appoinment_percentage_change
    : 0;
  return (
    <div className="dashboard-gradient-bg transition flex items-center justify-center md:items-start md:justify-start md:relative py-4 2xl:w-[1300px] 2xl:mx-auto rounded-2xl">
      <div className="flex flex-col  justify-center px-4">
        <h5 className="text-[20px] text-center md:text-start lg:text-[24px] font-medium font-poppins text-dark">
          Visit For Today
        </h5>
        <h3 className="text-[40px] text-center md:text-start lg:text-[60px] text-dark font-poppins font-medium">
          {analyticsData?.todays_appointment}
        </h3>
        <div className="flex items-center">
          <div className="w-[150px] px-2 py-2 2xl:h-[120px] 2xl:w-[220px] rounded-xl bg-[#D7F1Fc]">
            <h5 className="text-dark font-medium font-poppins text-[14px]">
              Students
            </h5>
            <div className="flex items-center justify-between mt-2">
              <h3 className="text-dark font-medium font-poppins text-[24px] lg:text-[30px]">
                {analyticsData?.new_users_this_month
                  ? analyticsData?.new_users_this_month
                  : 0}
              </h3>
              <div className="flex items-center bg-successLight p-2 rounded-full">
                <p
                  className={`text-[14px] font-poppins font-normal ${
                    studentsPercentage > 0 ? 'text-[#008000]' : 'text-[#D30404]'
                  }`}
                >
                  {studentsPercentage}%
                </p>
                {studentsPercentage > 0 ? (
                  <svg
                    width="20"
                    height="14"
                    viewBox="0 0 26 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.6016 0.88855L24.9237 0.88855V6.42039"
                      stroke="#008000"
                      strokeWidth="1.58074"
                    />
                    <path
                      d="M1.21313 15.1151L10.6976 5.63066L15.4398 10.3729L24.9243 0.888428"
                      stroke="#008000"
                      strokeWidth="1.58074"
                    />
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="14"
                    viewBox="0 0 16 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.4451 11.1173L14.9691 14.9192L11.6424 16.2527"
                      stroke="#D30404"
                      strokeWidth="1.02413"
                    />
                    <path
                      d="M0.697994 4.09016L8.68795 7.50743L6.97931 11.5024L14.9693 14.9197"
                      stroke="#D30404"
                      strokeWidth="1.02413"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>
          <div className="w-[150px] ml-3 px-2 py-2 2xl:h-[120px] 2xl:w-[220px] rounded-xl bg-[#D7F1Fc]">
            <h5 className="text-dark font-medium font-poppins text-[14px]">
              Appointments
            </h5>
            <div className="flex items-center justify-between mt-2">
              <h3 className="text-dark font-medium font-poppins text-[24px] lg:text-[30px]">
                {analyticsData?.new_appoinment_this_month
                  ? analyticsData?.new_appoinment_this_month
                  : 0}
              </h3>
              <div className="flex items-center bg-successLight  p-2 rounded-full">
                <p
                  className={`text-[14px] font-poppins font-normal ${
                    appointmentsPercentage > 0
                      ? 'text-[#008000]'
                      : 'text-[#D30404]'
                  }`}
                >
                  {appointmentsPercentage}%
                </p>
                {appointmentsPercentage > 0 ? (
                  <svg
                    width="20"
                    height="14"
                    viewBox="0 0 26 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.6016 0.88855L24.9237 0.88855V6.42039"
                      stroke="#008000"
                      strokeWidth="1.58074"
                    />
                    <path
                      d="M1.21313 15.1151L10.6976 5.63066L15.4398 10.3729L24.9243 0.888428"
                      stroke="#008000"
                      strokeWidth="1.58074"
                    />
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="14"
                    viewBox="0 0 16 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.4451 11.1173L14.9691 14.9192L11.6424 16.2527"
                      stroke="#D30404"
                      strokeWidth="1.02413"
                    />
                    <path
                      d="M0.697994 4.09016L8.68795 7.50743L6.97931 11.5024L14.9693 14.9197"
                      stroke="#D30404"
                      strokeWidth="1.02413"
                    />
                  </svg>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="md:absolute hidden md:block md:top-[-68px] md:right-[5px] 2xl:top-[-37px] 2xl:right-[0px]">
        <img
          src={StudentImg}
          alt="admin-dash-img"
          loading="lazy"
          className="lg:h-[316px] h-[272px] md:w-[200px] lg:w-[430px] 2xl:w-[400px]"
        />
      </div>
    </div>
  );
};

export default DashboardBanner;
