import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import ForgotPassword from 'pages/auth/ForgotPassword';
import Login from 'pages/auth/Login';
import ResetPassword from 'pages/auth/ResetPassword';
import SignUp from 'pages/auth/SignUp';

const UnAuthenticatedRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="*" element={<Navigate to="/login" replace={true} />} />
      </Routes>
    </>
  );
};

export default UnAuthenticatedRoutes;
