import React, { useEffect, useRef, useState } from 'react';
import { BsFillChatDotsFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { AdminChatModal } from './AdminChatModal';
import Pusher from 'pusher-js';
import { setNewUserNotification } from 'redux/actions/ActionCreators/NotificationsCountActions';

const AdminChatComponent = () => {
  const clickRef = useRef();
  const dispatch = useDispatch();
  const userId = 1;
  const notificationCount = useSelector(
    (state) => state?.notification?.notificationCount
  );

  const [showChatModal, setShowChatModal] = useState(false);

  // Detect Outside Click
  const handleClickOutSide = (e) => {
    if (clickRef.current && !clickRef.current.contains(e.target)) {
      setShowChatModal(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutSide, true);
  }, []);

  const handleOpenChats = () => {
    setShowChatModal(true);
  };

  const handleConfirm = () => {
    setShowChatModal(false);
  };

  // Init Pusher
  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
      cluster: 'us2',
      encrypted: true,
    });
    // subscribe notification chanel
    const notification = pusher.subscribe('notifications');
    // Binding chanel to pusher event and updating notification count
    notification.bind('new-chat', (data) => {
      if (userId == data?.receiver_id) {
        dispatch(setNewUserNotification());
      }
    });
    return () => {
      pusher.unsubscribe('notifications');
    };
  }, []);

  return (
    <div className="z-10 fixed bottom-4 right-4">
      {showChatModal && <AdminChatModal onConfirm={handleConfirm} />}
      <div className="flex items-end justify-end">
        <div
          className="bg-white w-[60px] py-4 flex items-center shadow-md justify-center rounded-full cursor-pointer relative"
          onClick={() => handleOpenChats()}
        >
          <BsFillChatDotsFill size={30} className="text-success" />

          {notificationCount > 0 && (
            <div className="bg-success h-[28px] w-[28px] absolute top-[-10px] right-0 rounded-full flex items-center justify-center">
              <p className="text-[14px] text-white font-figtree font-medium">
                {notificationCount > 99 ? '99+' : notificationCount}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminChatComponent;
