import React from 'react';
import ProfileIcon from 'assets/images/profile-icon.png';

const StudentProfile = ({ user, userInfo }) => {
  return (
    <div className="bg-white transition rounded-[12px] w-full px-4 lg:px-6 py-4 flex  flex-col  2xl:w-[600px] ">
      <div className="flex transition items-center">
        <img
          src={ProfileIcon}
          loading="lazy"
          alt="icon"
          className="w-[12px] h-[12px] mr-3"
        />
        <h5 className="text-[16px] text-blueDacker leading-6 font-figtree font-medium">
          User Details
        </h5>
      </div>
      <div className="flex items-center py-2 border-b border-lightgray">
        <h5 className="text-[16px] w-[170px] text-gray font-figtree font-regular">
          Name:
        </h5>
        <h3 className="text-[16px] capitalize ml-4 text-dark font-figtree font-regular">
          {user?.name}
        </h3>
      </div>
      <div className="flex items-center py-2 border-b border-lightgray">
        <h5 className="text-[16px] w-[170px] text-gray font-figtree font-regular">
          Father Name:
        </h5>
        <h3 className="text-[16px] capitalize ml-4 text-dark font-figtree font-regular">
          {userInfo?.father_name}
        </h3>
      </div>
      <div className="flex items-center py-2 border-b border-lightgray">
        <h5 className="text-[16px] w-[170px] text-gray font-figtree font-regular">
          Email:
        </h5>
        <h3 className="text-[16px] ml-4 text-dark font-figtree font-regular">
          {user?.email}
        </h3>
      </div>
      <div className="flex items-center  py-2 border-b border-lightgray">
        <h5 className="text-[16px] w-[170px] text-gray font-figtree font-regular">
          Date Of Birth:
        </h5>
        <h3 className="text-[16px] ml-4 text-dark font-figtree font-regular">
          {userInfo?.date_of_birth}
        </h3>
      </div>
      <div className="flex items-center py-2 border-b border-lightgray">
        <h5 className="text-[16px] w-[170px] text-gray font-figtree font-regular">
          NIC:
        </h5>
        <h3 className="text-[16px] ml-4 text-dark font-figtree font-regular">
          {userInfo?.nic}
        </h3>
      </div>
      <div className="flex items-center py-2 border-b border-lightgray">
        <h5 className="text-[16px] w-[170px] text-gray font-figtree font-regular">
          NIC Expiry Date:
        </h5>
        <h3 className="text-[16px] ml-4 text-dark font-figtree font-regular">
          {userInfo?.nic_expire_date}
        </h3>
      </div>
      <div className="flex items-center py-2 border-b border-lightgray">
        <h5 className="text-[16px] w-[170px] text-gray font-figtree font-regular">
          Passport No:
        </h5>
        <h3 className="text-[16px] ml-4 text-dark font-figtree font-regular">
          {userInfo?.passport_no}
        </h3>
      </div>
      <div className="flex items-center py-2 border-b border-lightgray">
        <h5 className="text-[16px] w-[170px] text-gray font-figtree font-regular">
          Passport Expiry Date:
        </h5>
        <h3 className="text-[16px] ml-4 text-dark font-figtree font-regular">
          {userInfo?.passport_expire_date}
        </h3>
      </div>
      <div className="flex items-center py-2 border-b border-lightgray">
        <h5 className="text-[16px] w-[170px] text-gray font-figtree font-regular">
          Address:
        </h5>
        <h3 className="text-[16px] break-anywhere ml-4 text-dark font-figtree font-regular">
          {userInfo?.home_address}
        </h3>
      </div>
      <div className="flex items-center py-2 border-b border-lightgray">
        <h5 className="text-[16px] w-[170px] text-gray font-figtree font-regular">
          Religion:
        </h5>
        <h3 className="text-[16px] ml-4 text-dark font-figtree font-regular">
          {userInfo?.religion}
        </h3>
      </div>
    </div>
  );
};

export default StudentProfile;
