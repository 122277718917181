import { Fragment, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { RiCloseCircleFill } from 'react-icons/ri';
import { BiSolidConversation } from 'react-icons/bi';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import classes from 'components/modal/Modal.module.css';
import AdminChatSidebar from './AdminChatSidebar';
import AdminChatPreview from './AdminChatPreview';
import DetectClickOutside from 'modules/DetectClickOutside';
import { config } from 'helpers/config/Config';
import { clearNotificationCount } from 'redux/actions/ActionCreators/NotificationsCountActions';

export const TheAdminChatModal = (props) => {
  const clickRef = useRef();
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.auth?.user?.access_token);
  const [conversationsList, setConversationsList] = useState([]);
  const [chatsLoading, setChatsLoading] = useState(false);
  const [selected, setSelected] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const [searchText, setSearchText] = useState('');

  // Function To Get All Conversations List
  const getRecentChats = async (search) => {
    try {
      setChatsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/recent-chat?query=${search}`,
        config(token)
      );
      setConversationsList(response?.data?.recent_chat);
      setChatsLoading(false);
      dispatch(clearNotificationCount());
    } catch (error) {
      console.log(error);
      setChatsLoading(false);
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  useEffect(() => {
    getRecentChats(searchText);
  }, [searchText]);

  return (
    <div>
      {/* <DetectClickOutside clickRef={clickRef} onConfirm={props.onConfirm} /> */}
      <div className={classes.backdrop}>
        <div
          className={`${classes.modal} ${classes.card}  w-[95%] h-[90%] md:w-[80%]`}
          ref={clickRef}
        >
          <header
            className={`${classes.header} flex items-center justify-between `}
          >
            <h3 className=" text-[16px] lg:text-[24px] font-figtree leading-8 font-medium text-white ">
              Chats
            </h3>
            <RiCloseCircleFill
              size={25}
              className="text-white cursor-pointer"
              onClick={props.onConfirm}
            />
          </header>
          <div className={`h-[95%] overflow-hidden custom-scroll-bar`}>
            <div className="grid grid-cols-12 h-full">
              <AdminChatSidebar
                loading={chatsLoading}
                recentChats={conversationsList}
                selectedChat={selectedChat}
                setSelected={setSelected}
                setSelectedChat={setSelectedChat}
                setSearchText={setSearchText}
                searchText={searchText}
              />
              {selected ? (
                <AdminChatPreview userId={selectedChat} />
              ) : (
                <div className="col-span-7 md:col-span-8 lg:col-span-9 h-full  w-full flex flex-col justify-center items-center relative">
                  <BiSolidConversation className="text-success" size={30} />

                  <p className="text-[14px] md:text-[18px] font-figtree font-normal text-center">
                    Please select any conversation to start chat
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const AdminChatModal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <TheAdminChatModal
          onConfirm={props.onConfirm}
          id={props?.id}
          page={props?.page}
        />,
        document.getElementById('modal')
      )}
    </Fragment>
  );
};
