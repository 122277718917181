import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RiCloseCircleFill } from "react-icons/ri";

import { config } from "helpers/config/Config";
import Spinner from "components/spinner/Spinner";

const UserNotifications = ({
  setShowNotifications,
  notificationsData,
  setRerenderNotifications,
}) => {
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state?.auth?.user?.access_token);
  const userId = useSelector((state) => state?.user?.user_info?.user[0]?.id);
  const clickRef = useRef();

  // Handle Detect Click Outside
  const handleClickOutside = (e) => {
    if (clickRef.current && !clickRef.current.contains(e.target)) {
      setShowNotifications(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  });

  // Function To Clear Notifications
  const clearNotifications = async () => {
    try {
      setLoading(true);
      let formData = {
        user_id: userId,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/user/read-notifaction`,
        formData,
        config(token)
      );
      setRerenderNotifications(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error(error.response?.data.message || error?.message);
    }
  };
  return (
    <div
      ref={clickRef}
      className="absolute bg-white h-[400px] top-14 w-[250px] md:w-[300px] right-[-150px] md:right-[-100px] rounded-md shadow-md z-10"
    >
      <div className="flex items-center justify-center relative border-b border-b-lightgray">
        <h3 className="text-center text-success text-[16px] font-figtree py-2">
          Notifications
        </h3>
        <RiCloseCircleFill
          size={20}
          className="absolute right-2 text-success cursor-pointer"
          onClick={() => setShowNotifications(false)}
        />
      </div>
      {loading && (
        <div className="flex items-center justify-center h-full flex-col">
          <Spinner />
        </div>
      )}
      <div className="h-[calc(100%-65px)] overflow-auto custom-scroll-bar">
        {notificationsData?.unread_messages?.length && !loading ? (
          notificationsData?.unread_messages?.map((data, index) => (
            <div className="py-2 border-b border-b-lightgray" key={index}>
              <p className="px-2 text-[14px] text-gray font-figtree font-normal break-all">
                {data?.message}
              </p>
            </div>
          ))
        ) : (
          <p className="text-center text-[14px] h-full flex justify-center items-center flex-col font-figtree font-normal">
            No Recent Notifications Found
          </p>
        )}
      </div>
      {notificationsData?.unread_messages?.length ? (
        <button
          onClick={() => clearNotifications()}
          className="h-[30px] w-full bg-success text-white rounded-b-md  text-[16px] font-figtree font-normal"
        >
          Clear All
        </button>
      ) : (
        ""
      )}
    </div>
  );
};

export default UserNotifications;
