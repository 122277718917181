import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ProfileIcon from "assets/images/profile-icon.png";
import UserImg from "assets/images/user.png";
import { removeUserProfileImg } from "redux/actions/ActionCreators/UserActions";
import ProfileForm from "./ProfileForm";

const ProfileDetails = () => {
  const dispatch = useDispatch();
  const inputFile = useRef(null);
  const [file, setFile] = useState(null);
  const [defaultImg, setDefaultImg] = useState(true);
  const [formImg, setFormImage] = useState(null);
  const validImagesTypes = ["image/jpeg", "image/png", "image/jpg"];
  const profileImg = useSelector((state) => state?.user?.profilePic);
  const token = useSelector((state) => state?.auth?.user?.access_token);

  // Hanlder For change input file change
  const handleFileChange = (e) => {
    if (validImagesTypes.includes(e.target.files[0]?.type)) {
      setFile(e.target.files[0]);
      setFormImage(e.target.files[0]);
      setDefaultImg(false);
    } else {
      toast.error("Invalid Image Type");
    }
  };

  // Convert File into base64 url
  useEffect(() => {
    if (!defaultImg) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setFile(reader.result);
      };
      setDefaultImg(true);
    }
  }, [defaultImg]);

  // Set Profile Image if url exists
  useEffect(() => {
    if (profileImg) {
      setFile(profileImg?.base64_encoded_document);
    }
  }, []);

  return (
    <div className="bg-white transition rounded-[12px] w-full px-6 py-6 flex  flex-col lg:flex-row gap-12  2xl:w-[1300px] 2xl:mx-auto lg:min-h-[680px]">
      <div className="">
        <div className="flex transition items-center">
          <img
            src={ProfileIcon}
            loading="lazy"
            alt="icon"
            className="w-[12px] h-[12px] mr-3"
          />
          <h5 className="text-[16px] text-blueDacker leading-6 font-figtree font-medium">
            Profile
          </h5>
        </div>
        <img
          src={file ? file : UserImg}
          loading="lazy"
          alt="user-img"
          className="mx-auto lg:mx-0 w-[230px] h-[230px] rounded-[16px] my-3 object-cover"
        />
        <input
          type="file"
          className="hidden"
          onChange={handleFileChange}
          accept="image/png, image/jpg, image/jpeg"
          ref={inputFile}
        />
        <button
          className="relative mx-auto lg:mx-0 my-3 w-[230px] h-[36px] md:h-[44px] bg-success rounded-[3px] text-white font-figtree font-medium text-centerrelative  items-center justify-start block text-[14px]  text-center  px-4  boder-0 outline-none  overflow-hidden  transition-all   hover:bg-white hover:border border-success group"
          onClick={() => inputFile.current.click()}
        >
          <span className="absolute inset-0 border-0 group-hover:border-[25px] ease-linear duration-100 transition-all border-white rounded-[6px]"></span>
          <span className="relative w-full text-center text-white transition-colors duration-200 ease-in-out group-hover:text-success">
            Change Image
          </span>
        </button>
        <button
          className="block mx-auto lg:mx-0 mb-3 w-[230px] h-[36px] md:h-[44px] bg-lightgray rounded-[3px] text-dark font-figtree font-medium text-[14px] text-center"
          onClick={() => dispatch(removeUserProfileImg(token, setFile))}
        >
          Remove Picture
        </button>
      </div>
      <div className="w-full">
        <ProfileForm profileImage={formImg} />
      </div>
    </div>
  );
};

export default ProfileDetails;
