import React from "react";
import { AiOutlineRight } from "react-icons/ai";
import { FaCheck } from "react-icons/fa";

const TabItem = ({ id, title, activeTab, setActiveTab, matchedDocument }) => {
  // Handle Tab Click
  const handleClick = () => {
    setActiveTab(id);
    window.scrollTo(0, 0);
  };
  return (
    <div
      className={`${
        activeTab === id ? "bg-lightgray" : ""
      } py-4 border-b border-lightgray cursor-pointer`}
      onClick={handleClick}
    >
      <div className="px-2 lg:px-4 flex items-center justify-between">
        <p className="text-dark text-[12px] font-normal lg:text-[14px] lg:font-bold font-figtree ">
          {title}
        </p>
        {matchedDocument ? (
          <FaCheck size={15} className="text-success hidden md:block" />
        ) : (
          <AiOutlineRight size={15} className="text-dark hidden md:block" />
        )}
      </div>
    </div>
  );
};

export default TabItem;
