import React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { loginWithGoogle } from "redux/actions/ActionCreators/AuthActions";

const GoogleLoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const responseGoogle = (response) => {
    try {
      let userObject = jwt_decode(response?.credential);
      let data = {};
      data.name = userObject?.given_name + " " + userObject?.family_name;
      data.email = userObject?.email;
      data.password = userObject?.sub;
      data.profile_picture = userObject?.picture;
      dispatch(loginWithGoogle(data, navigate));
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="w-full my-3 bg-white justify-center flex">
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <GoogleLogin
          render={(renderProps) => (
            <button
              type="button"
              className=""
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.4632 8.39155H18.7058V8.35294H10.2353V12.1177H15.5551C14.7775 14.3088 12.693 15.8824 10.2353 15.8824C7.11577 15.8824 4.58819 13.3548 4.58819 10.2353C4.58819 7.11581 7.11577 4.58824 10.2353 4.58824C11.6746 4.58824 12.9853 5.13052 13.9816 6.01839L16.6433 3.35662C14.9632 1.79044 12.715 0.823532 10.2353 0.823532C5.03672 0.823532 0.823486 5.03677 0.823486 10.2353C0.823486 15.4338 5.03672 19.6471 10.2353 19.6471C15.4338 19.6471 19.647 15.4338 19.647 10.2353C19.647 9.60478 19.5827 8.98897 19.4632 8.39155Z"
                  fill="#FFC107"
                />
                <path
                  d="M1.90808 5.85478L5.00183 8.12317C5.83823 6.05147 7.86396 4.58824 10.2353 4.58824C11.6746 4.58824 12.9853 5.13052 13.9816 6.01839L16.6434 3.35662C14.9632 1.79044 12.7151 0.823532 10.2353 0.823532C6.61948 0.823532 3.48529 2.86397 1.90808 5.85478Z"
                  fill="#FF3D00"
                />
                <path
                  d="M10.2352 19.6471C12.6654 19.6471 14.8749 18.7169 16.5459 17.204L13.6323 14.739C12.6874 15.454 11.5128 15.8824 10.2352 15.8824C7.78671 15.8824 5.70951 14.3217 4.92642 12.1434L1.85657 14.5074C3.41355 17.5552 6.57716 19.6471 10.2352 19.6471Z"
                  fill="#4CAF50"
                />
                <path
                  d="M19.4632 8.39155H18.7058V8.35295H10.2352V12.1177H15.5551C15.1819 13.171 14.5036 14.0772 13.6305 14.739C13.6323 14.739 13.6323 14.739 13.6323 14.739L16.5459 17.2041C16.34 17.3897 19.647 14.9412 19.647 10.2353C19.647 9.60479 19.5827 8.98898 19.4632 8.39155Z"
                  fill="#1976D2"
                />
              </svg>
              <span className="text-dark text-[14px] leading-[20px] font-jakarta-sans font-medium ml-2">
                Sign in with Google
              </span>
              <div className="absolute right-4">
                <svg
                  width="6"
                  height="10"
                  viewBox="0 0 6 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.288427 8.40716L3.81292 5.21957C3.82147 5.21184 3.82962 5.20366 3.83733 5.19508C3.96034 5.05811 3.94902 4.84736 3.81205 4.72435L0.328949 1.59629C0.0204092 1.3192 -0.00567565 0.843796 0.270687 0.534445C0.547049 0.225094 1.02121 0.19894 1.32975 0.47603L5.70954 4.40938C6.04156 4.70756 6.04247 5.22854 5.71149 5.52788L1.29313 9.52391C0.985557 9.80208 0.511312 9.77759 0.233872 9.46921C-0.0435686 9.16083 -0.0191433 8.68533 0.288427 8.40716Z"
                    fill="#252A31"
                  />
                </svg>
              </div>
            </button>
          )}
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy="single_host_origin"
        />
      </GoogleOAuthProvider>
    </div>
  );
};

export default GoogleLoginPage;
