import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BiArrowBack } from "react-icons/bi";

import DocumentsTable from "components/dashboard/DocumentsTable";
import NoRecord from "components/norecord/NoRecord";
import { config } from "helpers/config/Config";
import DocsIcon from "assets/images/upload-docs.png";
import ApplicationIcon from "assets/images/application-icon.png";
import AppointmentIcon from "assets/images/appoint-icon.png";
import StudentProfile from "admin/components/students/StudentProfile";
import AdminAppointmentsTable from "admin/components/appointments/AdminAppointmentsTable";
import AnimatedLoading from "components/spinner/AnimatedLoading";
import AdminApplicationTable from "admin/components/applications/AdminApplicationsTable";
import { AddAppointmentFromStudentDetails } from "components/modal/AddAppointmentFromStudentDetails";
import { AddApplicationFromStudentDetails } from "components/modal/AddApplicationFromStudentDetails";

const StudentDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const token = useSelector((state) => state?.auth?.user?.access_token);

  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [reRenderStudentDetails, setreRenderStudentDetails] = useState(false);
  const [addAppointmentModal, setAddAppointmentModal] = useState(false);
  const [addApplicationModal, setAddApplicationModal] = useState(false);

  // Function Get Student Details
  const getDetails = async (id) => {
    try {
      setLoading(true);
      let response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/student-details/${id}`,
        config(token)
      );
      setUserData(response?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
  // Call Students details function when id changes
  useEffect(() => {
    getDetails(id);
  }, [id]);

  useEffect(() => {
    if (reRenderStudentDetails) {
      getDetails(id);
      setreRenderStudentDetails(false);
    }
  }, [reRenderStudentDetails]);

  // Loading
  if (loading) {
    return (
      <div className="flex items-center justify-center h-[calc(100vh-90px)]">
        <AnimatedLoading />
      </div>
    );
  }

  const handleConfirmModal = () => {
    setAddAppointmentModal(false);
    setAddApplicationModal(false);
  };
  return (
    <div className="min-h-full">
      {/* Add Appointment Modal component */}
      {addAppointmentModal && (
        <AddAppointmentFromStudentDetails
          onConfirm={handleConfirmModal}
          id={id}
          userName={userData?.user[0]?.name}
          renderStudentDeatils={setreRenderStudentDetails}
        />
      )}
      {/* Add Application Modal component */}
      {addApplicationModal && (
        <AddApplicationFromStudentDetails
          onConfirm={handleConfirmModal}
          id={id}
          userName={userData?.user[0]?.name}
          renderStudentDeatils={setreRenderStudentDetails}
        />
      )}
      <div className="flex items-center justify-between">
        <h3 className="py-6 capitalize text-[20px] lg:text-[28px] font-figtree leading-8 font-bold text-black 2xl:w-[1300px] 2xl:mx-auto">
          Details
        </h3>
        <div
          className="flex items-center cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <BiArrowBack size={15} className="text-success" />
          <span className="text-[14px] font-figtree text-success">Back</span>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 2xl:gap-2 2xl:flex  2xl:justify-center 2xl:w-[full]">
        <StudentProfile
          user={userData?.user[0]}
          userInfo={userData?.userInfo[0]}
        />
        <div className="bg-white transition rounded-[12px] w-full px-2 md:px-4 lg:px-6 py-4 flex  flex-col  2xl:w-[700px] lg:max-h-[500px]">
          <div className="flex items-center">
            <img
              src={DocsIcon}
              loading="lazy"
              alt="icon"
              className="h-[16px] w-[16px] mr-3"
            />
            <h5 className="text-[16px] leading-6 font-figtree font-medium text-blueDacker">
              Uploaded Documents
            </h5>
          </div>
          {userData?.userDoc?.length ? (
            <div className="overflow-y-scroll grid grid-cols-1 custom-scroll-bar overflow-x-hidden pt-4">
              <DocumentsTable
                documentsData={userData?.userDoc}
                renderStudentDeatils={setreRenderStudentDetails}
              />
            </div>
          ) : (
            <div className="text-center flex items-center flex-col h-full justify-center text-dark">
              <NoRecord title={`Sorry, we couldn't find any documents data.`} />
            </div>
          )}
        </div>
      </div>
      <div className="bg-white my-4 transition rounded-[12px] 2xl:w-[1300px] md:w-full px-2 md:px-4 lg:px-6 py-4 flex  flex-col  2xl:mx-auto max-h-[450px] ">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <img
              src={AppointmentIcon}
              loading="lazy"
              alt="icon"
              className="h-[16px] w-[16px] mr-3"
            />
            <h5 className="text-[16px] whitespace-nowrap leading-6 font-figtree font-medium text-blueDacker">
              Appointments List
            </h5>
          </div>
          <button
            className="relative block  w-[120px] h-[30px] md:h-[30px] bg-success rounded-[3px] text-white font-figtree font-medium text-centerrelative  items-center justify-start  text-[12px]  text-center  px-2  boder-0 outline-none  overflow-hidden  transition-all   hover:bg-white hover:border border-success group"
            onClick={() => setAddAppointmentModal(true)}
          >
            <span className="absolute inset-0 border-0 group-hover:border-[25px] ease-linear duration-100 transition-all border-white rounded-[6px]"></span>
            <span className="relative w-full text-center text-white transition-colors duration-200 ease-in-out group-hover:text-success">
              Add Appointment
            </span>
          </button>
        </div>
        {userData?.appointments?.length ? (
          <div className="overflow-y-scroll grid grid-cols-1 custom-scroll-bar overflow-x-hidden pt-4">
            <AdminAppointmentsTable
              appointmentsData={userData?.appointments}
              renderStudentDeatils={setreRenderStudentDetails}
              userName={userData?.user[0]?.name}
            />
          </div>
        ) : (
          <div className="text-center flex items-center flex-col h-full justify-center text-dark">
            <NoRecord
              title={`Sorry, we couldn't find any appointments data.`}
            />
          </div>
        )}
      </div>
      <div className="bg-white my-4 transition rounded-[12px] 2xl:w-[1300px] md:w-full px-2 md:px-4 lg:px-6 py-4 flex  flex-col  2xl:mx-auto max-h-[450px] ">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <img
              src={ApplicationIcon}
              loading="lazy"
              alt="icon"
              className="h-[26px] w-[26px] mr-3"
            />
            <h5 className="text-[16px] whitespace-nowrap leading-6 font-figtree font-medium text-blueDacker">
              Applications List
            </h5>
          </div>
          <button
            className="relative block  w-[120px] h-[30px] md:h-[30px] bg-success rounded-[3px] text-white font-figtree font-medium text-centerrelative  items-center justify-start  text-[12px]  text-center  px-2  boder-0 outline-none  overflow-hidden  transition-all   hover:bg-white hover:border border-success group"
            onClick={() => setAddApplicationModal(true)}
          >
            <span className="absolute inset-0 border-0 group-hover:border-[25px] ease-linear duration-100 transition-all border-white rounded-[6px]"></span>
            <span className="relative w-full text-center text-white transition-colors duration-200 ease-in-out group-hover:text-success">
              Add Application
            </span>
          </button>
        </div>
        {userData?.application?.length ? (
          <div className="overflow-y-scroll grid grid-cols-1 custom-scroll-bar overflow-x-hidden pt-4">
            <AdminApplicationTable
              applicationsData={userData?.application}
              renderStudentDeatils={setreRenderStudentDetails}
            />
          </div>
        ) : (
          <div className="text-center flex items-center flex-col h-full justify-center text-dark">
            <NoRecord
              title={`Sorry, we couldn't find any applications data.`}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentDetails;
