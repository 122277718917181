import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import ProfileField from "components/textfield/ProfileField";
import Spinner from "components/spinner/Spinner";
import SelectTextField from "components/textfield/SelectField";
import {
  adminCreateApplication,
  adminUpdateApplication,
} from "redux/actions/ActionCreators/AdminActions";
import MultiSelectTextField from "components/textfield/MultiSelectField";
import { documentsOptionList } from "modules/DocumentOptionsList";

const ApplicationForm = ({ id, onConfirm, page }) => {
  const dispatch = useDispatch();

  const token = useSelector((state) => state.auth.user.access_token);
  const loading = useSelector((state) => state.user.loading);
  const usersData = useSelector((state) => state?.admin?.allStudents);
  const applications = useSelector((state) => state?.admin?.applications);

  const [applicationData, setApplicationData] = useState(null);
  const [selectUser, setUservalue] = useState({
    label: "Select User",
    value: "",
  });
  const [selectRequiredDocuments, setRequiredDocument] = useState([""]);
  const [applicationStatus, setApplicationStatus] = useState({
    label: "Select Status",
    value: "",
  });

  // Status Options List
  const statusOptions = [
    {
      value: 0,
      label: "Pending",
    },
    {
      value: 1,
      label: "Processing",
    },
    {
      value: 2,
      label: "Rejected",
    },
  ];

  // User Options List
  const options = usersData.length
    ? usersData.map((data) => {
        return {
          value: data.id,
          label: `${data.name} (${data?.email})`,
        };
      })
    : [];

  //Form Initial Values
  let initialValues = {
    user: applicationData?.user_id,
    university_name: applicationData?.applied_university,
    course_name: applicationData?.course_name,
    required_docs: Array.isArray(applicationData?.required_doc)
      ? applicationData?.required_doc?.map((data) => data?.value)
      : [],
    status: applicationData?.status,
  };

  // Error Schema
  const errorSchema = Yup.object().shape({
    user: Yup.string().required("User is required"),
    university_name: Yup.string().required("University Name is required"),
    required_docs: Yup.array().min(1).required("Select required documents"),
    course_name: Yup.string().required("Course Name is required"),
  });

  // Form Submission
  const handleSubmit = (values, { resetForm }) => {
    // Payload Data
    let data = {
      user_id: values?.user,
      applied_university: values?.university_name,
      course_name: values?.course_name,
      requiredDocuments: JSON.stringify(values?.required_docs),
      status: values.status,
      Application_no: applicationData?.application_no,
    };
    const currentPage =
      applications?.data?.length === 1 && page > 1 ? page + 1 : page;
    if (id) {
      dispatch(adminUpdateApplication(data, currentPage, token));
    } else {
      dispatch(adminCreateApplication(data, page, token));
    }
    // Close Modal
    onConfirm();
  };

  // Find Single Application base on application id (Edit Application Case)
  useEffect(() => {
    if (id) {
      setApplicationData(applications?.data?.find((data) => data?.id == id));
    }
  }, [id]);

  // Set User name and status values  (Edit Application Case)
  useEffect(() => {
    if (id) {
      setUservalue({
        label: `${applicationData?.userName} (${applicationData?.userEmail})`,
        value: applicationData?.user_id,
      });
      setApplicationStatus({
        label:
          applicationData?.status == 0
            ? "Pending"
            : applicationData?.status == 1
            ? "Approved"
            : "Rejected",
        value: applicationData?.status,
      });
      setRequiredDocument(applicationData?.required_doc);
    }
  }, [applicationData]);
  console.log(applicationData);

  return (
    <div className="bg-white transition rounded-[12px] w-full flex  flex-col lg:flex-row  ">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={errorSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form className="w-full">
            <MultiSelectTextField
              label="Select Required Documents"
              name="required_docs"
              placeholder="Select require documents"
              value={selectRequiredDocuments}
              options={documentsOptionList}
              isMulti
              onChange={(e) => {
                props.setFieldValue(
                  "required_docs",
                  e?.map((data) => data?.value)
                );
                setRequiredDocument(e);
              }}
            />
            <div className="w-full grid grid-cols-1 md:grid-cols-2 md:gap-2">
              <SelectTextField
                label="Select User"
                name="user"
                value={selectUser}
                options={options}
                onChange={(e) => {
                  props.setFieldValue("user", e.value);
                  setUservalue(e);
                }}
              />
              <SelectTextField
                label="Select Status"
                name="status"
                value={applicationStatus}
                options={statusOptions}
                onChange={(e) => {
                  props.setFieldValue("status", e.value);
                  setApplicationStatus(e);
                }}
              />
              <ProfileField
                type="text"
                label="University Name"
                name="university_name"
                placeholder="Enter university name"
              />
              <ProfileField
                type="text"
                label="Course Name"
                name="course_name"
                placeholder="Enter course name"
              />
            </div>

            <button className="relative  mx-auto  mt-3 w-full xl:w-[230px] h-[36px] md:h-[44px] bg-success rounded-[3px] text-white font-figtree font-medium text-centerrelative   items-center justify-center block  text-[14px]  text-center  px-4  boder-0 outline-none  overflow-hidden  transition-all   hover:bg-white hover:border border-success group">
              <span className="absolute inset-0 border-0 group-hover:border-[25px] ease-linear duration-100 transition-all border-white rounded-[6px]"></span>
              <span className="relative w-full text-center text-white transition-colors duration-200 ease-in-out group-hover:text-success">
                {loading ? (
                  <Spinner />
                ) : id ? (
                  "Update Application"
                ) : (
                  "Add Application"
                )}
              </span>
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ApplicationForm;
