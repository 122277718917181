import { Fragment, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { RiCloseCircleFill } from 'react-icons/ri';

import classes from './Modal.module.css';
import ApplicationForm from 'admin/components/applications/ApplicationForm';
import DetectClickOutside from 'modules/DetectClickOutside';

export const TheApplicationModal = (props) => {
  const clickRef = useRef('');

  return (
    <div>
      <div className={classes.backdrop}>
        {/* // Detect Click Outside the Modal */}
        <DetectClickOutside clickRef={clickRef} onConfirm={props.onConfirm} />
        <div className={`${classes.modal} ${classes.card}`} ref={clickRef}>
          <header
            className={`${classes.header} flex items-center justify-between `}
          >
            <h3 className=" text-[16px] lg:text-[24px] font-figtree leading-8 font-medium text-white ">
              {props?.id ? ' Edit Application' : ' Add Application'}
            </h3>
            <RiCloseCircleFill
              size={25}
              className="text-white cursor-pointer"
              onClick={props.onConfirm}
            />
          </header>
          <div
            className={`${classes.content} max-h-[500px]  overflow-y-auto md:overflow-auto md:max-h-[600px]  custom-scroll-bar`}
          >
            <ApplicationForm
              onConfirm={props?.onConfirm}
              page={props?.page}
              id={props?.id}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const ApplicationModal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <TheApplicationModal
          onConfirm={props.onConfirm}
          id={props?.id}
          page={props?.page}
        />,
        document.getElementById('modal')
      )}
    </Fragment>
  );
};
