import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import DashboardLayout from "layout/DashboardLayout";
import Applications from "pages/applications/Applications";
import AppointmentList from "pages/appointment/AppointmentList";
import ChangePassword from "pages/auth/ChangePassowrd";
import Dashboard from "pages/dashboard/Dashboard";
import Profile from "pages/profile/Profile";
import UserChatComponent from "components/chat/UserChatComponent";
import UploadDocumets from "pages/UploadDocs/UploadDocumets";
import { checkSession } from "redux/actions/ActionCreators/UserActions";

const UserRoutes = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.auth?.user?.access_token);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (token) {
      dispatch(checkSession(token));
    }
  }, [location]);
  return (
    <>
      <DashboardLayout>
        <UserChatComponent />
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/appointments" element={<AppointmentList />} />
          <Route path="/applications" element={<Applications />} />
          <Route path="/upload-documents" element={<UploadDocumets />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route
            path="*"
            element={<Navigate to="/dashboard" replace={true} />}
          />
        </Routes>
      </DashboardLayout>
    </>
  );
};

export default UserRoutes;
