import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import DashboardLayout from "layout/DashboardLayout";
import AdminDashboard from "admin/pages/dashboard/AdminDashboard";
import Appointemnts from "admin/pages/appointments/Appointemnts";
import Students from "admin/pages/students/Students";
import StudentDetails from "admin/pages/students/StudentDetails";
import Applications from "admin/pages/applications/Applications";
import AdminChatComponent from "admin/components/chat/AdminChatComponent";
import { checkSession } from "redux/actions/ActionCreators/UserActions";

const AdminRoutes = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.auth?.user?.access_token);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (token) {
      dispatch(checkSession(token));
    }
  }, [location]);
  return (
    <>
      <DashboardLayout>
        <AdminChatComponent />
        <Routes>
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/admin-appointments" element={<Appointemnts />} />
          <Route path="/admin-applications" element={<Applications />} />
          <Route path="/students" element={<Students />} />
          <Route path="/students/:id" element={<StudentDetails />} />
          <Route
            path="*"
            element={<Navigate to="/admin-dashboard" replace={true} />}
          />
        </Routes>
      </DashboardLayout>
    </>
  );
};

export default AdminRoutes;
