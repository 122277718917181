import { Fragment, useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { RiCloseCircleFill } from 'react-icons/ri';
import axios from 'axios';
import { toast } from 'react-toastify';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { useDispatch, useSelector } from 'react-redux';

import classes from './Modal.module.css';
import Spinner from 'components/spinner/Spinner';
import { config } from 'helpers/config/Config';
import { adminGetApplications } from 'redux/actions/ActionCreators/AdminActions';
import DetectClickOutside from 'modules/DetectClickOutside';

export const TheUploadOfferLetterModal = (props) => {
  const clickRef = useRef('');
  const fileRef = useRef();
  const dispatch = useDispatch();

  const [offerLetter, setOfferLetter] = useState(null);
  const [previewLetter, setPreviewLetter] = useState([]);
  const [loading, setLoading] = useState(false);

  const token = useSelector((state) => state?.auth?.user?.access_token);

  // Handle File Change
  const handleChange = (e) => {
    setOfferLetter(e.target.files[0]);
    setPreviewLetter(Array.from(e.target.files));
  };

  // Handle Upload Offer Letter
  const handleUploadOfferLetter = async () => {
    try {
      setLoading(true);
      let formData = new FormData();
      formData.append('Document', offerLetter);
      formData.append('Document_name', props?.doc_name);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/process-admission-related-doc/${props?.id}`,
        formData,
        config(token)
      );
      dispatch(adminGetApplications(props?.page, '', token));
      setLoading(false);
      toast.success(response?.data?.original?.message);
      props.onConfirm();
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  return (
    <div>
      <div className={classes.backdrop}>
        {/* // Detect Click Outside the Modal */}
        <DetectClickOutside clickRef={clickRef} onConfirm={props.onConfirm} />
        <div
          className={`${classes.modal} ${classes.card} max-h-[95%] 2xl:max-h-[50%]`}
          ref={clickRef}
        >
          <header
            className={`${classes.header} flex items-center justify-between `}
          >
            <h3 className=" text-[16px] lg:text-[24px] font-figtree leading-8 font-medium text-white ">
              {props?.title}
            </h3>
            <RiCloseCircleFill
              size={25}
              className="text-white cursor-pointer"
              onClick={props.onConfirm}
            />
          </header>
          <div className={`max-h-[500px] overflow-y-auto lg:max-h-[600px]`}>
            {loading ? (
              <div className="flex items-center justify-center py-4">
                <Spinner />
              </div>
            ) : (
              <div className="flex flex-col my-4 justify-center items-center h-full">
                <h3 className="text-[18px] font-figtree font-medium">
                  Upload Document
                </h3>
                <input
                  type="file"
                  className="hidden"
                  ref={fileRef}
                  onChange={handleChange}
                />
                <div className="flex items-center my-3">
                  <button
                    onClick={() => fileRef.current.click()}
                    className="relative block mx-auto w-[120px] h-[30px] md:h-[30px] bg-lightgray rounded-[3px] text-white font-figtree font-medium text-centerrelative  items-center justify-start  text-[12px]  text-center  px-2  boder-0 outline-none  overflow-hidden  transition-all   hover:bg-white hover:border border-lightgray group"
                  >
                    <span className="absolute inset-0 border-0 group-hover:border-[25px] ease-linear duration-100 transition-all border-white rounded-[6px]"></span>
                    <span className="relative w-full text-center text-gray transition-colors duration-200 ease-in-out group-hover:text-gray">
                      Select File
                    </span>
                  </button>
                  <button
                    className="relative block mx-4 w-[120px] h-[30px] md:h-[30px] bg-success rounded-[3px] text-white font-figtree font-medium text-centerrelative  items-center justify-start  text-[12px]  text-center  px-2  boder-0 outline-none  overflow-hidden  transition-all   hover:bg-white hover:border border-success group disabled:opacity-30"
                    disabled={!offerLetter}
                    onClick={() => handleUploadOfferLetter()}
                  >
                    <span className="absolute inset-0 border-0 group-hover:border-[25px] ease-linear duration-100 transition-all border-white rounded-[6px]"></span>
                    <span className="relative w-full text-center text-white transition-colors duration-200 ease-in-out group-hover:text-success">
                      Upload File
                    </span>
                  </button>
                </div>

                {offerLetter && (
                  <DocViewer
                    documents={previewLetter.map((file) => ({
                      uri: window.URL.createObjectURL(file),
                      fileName: file.name,
                    }))}
                    pluginRenderers={DocViewerRenderers}
                    theme={{
                      primary: '#00a58e',
                      secondary: '#00a58e',
                      tertiary: '#fff',
                      textSecondary: '#5296d8',
                      textTertiary: '#00000099',
                      disableThemeScrollbar: false,
                    }}
                    config={{
                      header: {
                        disableHeader: true,
                        disableFileName: true,
                        retainURLParams: true,
                      },
                      csvDelimiter: ',', // "," as default,
                      pdfZoom: {
                        defaultZoom: 1, // 1 as default,
                        zoomJump: 0.2, // 0.1 as default,
                      },
                      pdfVerticalScrollByDefault: true, // false as default
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const UploadOfferLetterModal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <TheUploadOfferLetterModal
          onConfirm={props.onConfirm}
          id={props.id}
          page={props?.page}
          title={props?.title}
          doc_name={props?.doc_name}
        />,
        document.getElementById('modal')
      )}
    </Fragment>
  );
};
