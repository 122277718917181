import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import 'assets/css/calendar.css';
import { CalenderAppointmentModal } from 'components/modal/CalenderAppointmentModal';

const localizer = momentLocalizer(moment);

const CalenderComponent = () => {
  const appointmentList = useSelector((state) => state?.admin?.allAppointments);

  const [showPopupModal, setShowPopupModal] = useState(false);
  const [appointmentId, setAppointmentId] = useState(null);

  const events = appointmentList?.length
    ? appointmentList?.map((data) => {
        return {
          start: moment(`${data?.date}T${data?.time}`).toDate(),
          end: moment(`${data?.date}T${data?.time}`).toDate(),
          title: data?.agenda,
          id: data?.id,
        };
      })
    : [];

  // Handler for selecting event
  const onSelectEvent = (event) => {
    setShowPopupModal(true);
    setAppointmentId(event?.id);
  };

  return (
    <div>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        defaultView="month"
        views={['month', 'agenda']}
        style={{ height: 450 }}
        step={30}
        popup={true}
        onShowMore={(events, date) => (events, date)}
        onSelectEvent={onSelectEvent}
      />
      {/* Pop Up Modal for Appointment Deatils and launch meeting */}
      {showPopupModal && (
        <CalenderAppointmentModal
          onConfirm={() => setShowPopupModal(false)}
          id={appointmentId}
        />
      )}
    </div>
  );
};

export default CalenderComponent;
