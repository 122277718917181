import React from 'react';
import NotFound from 'assets/images/not-found.png';

const NoRecord = ({ title }) => {
  return (
    <div className="flex flex-col justify-center items-center h-full ">
      <img src={NotFound} loading="lazy" alt="not-found-image" />
      <p className="text-dark font-bold font-jakarta-sans text-center">
        {title ? title : `Sorry, we couldn't find any data.`}
      </p>
    </div>
  );
};

export default NoRecord;
