import React, { useEffect, useRef, useState } from 'react';
import { BsFillChatDotsFill, BsFillSendFill } from 'react-icons/bs';
import { RiCloseCircleFill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Pusher from 'pusher-js';

import Messages from 'admin/components/chat/Messages';
import { config } from 'helpers/config/Config';
import {
  clearNotificationCount,
  setNewUserNotification,
} from 'redux/actions/ActionCreators/NotificationsCountActions';

const UserChatComponent = () => {
  const clickRef = useRef();
  const scrollRef = useRef();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.user_info?.user[0]);
  const token = useSelector((state) => state?.auth?.user?.access_token);
  const notificationCount = useSelector(
    (state) => state?.notification?.notificationCount
  );
  const [showChatModal, setShowChatModal] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [userMessages, setUserMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  // Load User Messages
  const loadUserChat = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/get-messages`,
        config(token)
      );
      setUserMessages(response?.data?.messages);
      setLoading(false);
      dispatch(clearNotificationCount());
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  // Init Pusher
  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
      cluster: 'us2',
      encrypted: true,
    });
    // subscribe chat chanel
    const channel = pusher.subscribe('chat');
    // subscribe notification chanel
    const notification = pusher.subscribe('notifications');
    // Binding chanel to pusher event and setting messages into state
    channel.bind('new-message', (data) => {
      setUserMessages((prv) => [...prv, data.message]);
    });
    // Binding chanel to pusher event and updating notification count
    notification.bind('new-chat', (data) => {
      if (user?.id == data?.receiver_id) {
        dispatch(setNewUserNotification());
      }
    });
    return () => {
      pusher.unsubscribe('chat');
      pusher.unsubscribe('notifications');
    };
  }, []);

  // Handler For send message
  const sendMessage = async (e) => {
    e.preventDefault();
    if (messageText.trim()) {
      try {
        let data = {
          message: messageText,
        };
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/send-message`,
          data,
          config(token)
        );
        setMessageText('');
      } catch (error) {
        console.log(error);
      }
    }
  };

  // Detect Outside Click
  const handleClickOutSide = (e) => {
    if (clickRef.current && !clickRef.current.contains(e.target)) {
      setShowChatModal(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutSide, true);
  }, []);

  // Scroll Into bottom (View)
  useEffect(() => {
    scrollRef.current?.scrollTo(0, scrollRef.current?.scrollHeight);
  }, [userMessages]);

  // Handler for open chat modal
  const handleOpenChats = () => {
    setShowChatModal(true);
    scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    loadUserChat();
  };

  return (
    <div className="fixed bottom-4 right-8 z-10">
      {showChatModal && (
        <div
          ref={clickRef}
          className="w-[300px] md:w-[350px] animate-fade-in-down relative h-[400px] mb-4 rounded-lg shadow-lg bg-white "
        >
          <div className="bg-success py-2 rounded-t-lg flex items-center justify-between ">
            <h3 className="px-2 text-white text-[16px] font-figtree font-medium">
              {user?.name}
            </h3>
            <RiCloseCircleFill
              size={20}
              className="text-white cursor-pointer mr-2"
              onClick={() => setShowChatModal(false)}
            />
          </div>
          <div
            ref={scrollRef}
            className="h-[calc(400px-80px)] bg-white overflow-y-auto custom-scroll-bar"
          >
            <Messages messages={userMessages} loading={loading} />
          </div>
          <div className="absolute bottom-0 rounded-b-lg w-full   bg-lightgray flex items-center">
            <form className="flex items-center w-full " onSubmit={sendMessage}>
              <input
                type="text"
                placeholder="Send message"
                value={messageText}
                onChange={(e) => setMessageText(e.target.value)}
                className="px-2 py-2  bg-transparent w-full outline-none"
              />
              <BsFillSendFill
                className="mr-4  text-success cursor-pointer"
                type="submit"
                role={'button'}
                onClick={sendMessage}
              />
            </form>
          </div>
        </div>
      )}
      <div className="flex items-end justify-end">
        <div
          className="bg-white shadow-lg w-[60px] py-4 flex items-center justify-center rounded-full cursor-pointer relative"
          onClick={() => handleOpenChats()}
        >
          <BsFillChatDotsFill
            size={30}
            className="text-success"
            onClick={() => loadUserChat()}
          />
          {notificationCount > 0 && (
            <div className="bg-success h-[28px] w-[28px] absolute top-[-10px] right-0 rounded-full flex items-center justify-center">
              <p className="text-[14px] text-white font-figtree font-medium">
                {notificationCount > 99 ? '99+' : notificationCount}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserChatComponent;
