import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import ProfileDetails from 'components/profile/ProfileDetails';
import ProfileDocuments from 'components/profile/ProfileDocuments';

const Profile = () => {
  const [activeProfile, setActiveProfile] = useState(true);
  const { state } = useLocation();

  // Set state active state tab
  useEffect(() => {
    if (state) {
      setActiveProfile(false);
    }
  }, [state]);

  return (
    <div className="">
      {/* Profile && Document Tab */}
      <div className=" flex  flex-row flex-wrap border-b-0 pl-0 transition">
        <h3
          className={`${
            activeProfile
              ? 'border-b-2 border-b-success text-success'
              : 'text-dark'
          } my-2 flex-grow basis-0 text-[18px] font-figtree font-medium text-center py-3 cursor-pointer`}
          onClick={() => setActiveProfile(true)}
        >
          Profile
        </h3>
        <h3
          className={`${
            !activeProfile
              ? 'border-b-2 border-b-success text-success'
              : 'text-dark'
          } my-2 flex-grow basis-0 text-[18px] font-figtree font-medium text-center py-3 cursor-pointer`}
          onClick={() => setActiveProfile(false)}
        >
          Documents
        </h3>
      </div>
      {/* Render Active Tab component */}
      {activeProfile ? (
        <div>
          <h3 className=" py-6 text-[20px] lg:text-[28px] font-figtree leading-8 font-bold text-black 2xl:w-[1300px] 2xl:mx-auto">
            Profile
          </h3>
          <div>
            <ProfileDetails />
          </div>
        </div>
      ) : (
        <div>
          <h3 className=" py-6 text-[20px] lg:text-[28px] font-figtree leading-8 font-bold text-black 2xl:w-[1300px] 2xl:mx-auto">
            Documents
          </h3>
          <div>
            <ProfileDocuments />
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
