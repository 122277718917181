import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ApplicationIcon from 'assets/images/application-icon.png';
import ApplicationTable from 'components/applications/ApplicationTable';
import { getApplications } from 'redux/actions/ActionCreators/UserActions';
import Pagination from 'modules/Pagination';
import NoRecord from 'components/norecord/NoRecord';
import AnimatedLoading from 'components/spinner/AnimatedLoading';

const Applications = () => {
  const dispatch = useDispatch();

  const token = useSelector((state) => state?.auth?.user?.access_token);
  const loading = useSelector((state) => state?.user?.loading);
  const userId = useSelector((state) => state?.user?.user_info?.user[0]?.id);
  const applications = useSelector((state) => state?.user?.applications);

  const [currentPage, setCurrentPage] = useState(0);

  // Get Applications data
  useEffect(() => {
    dispatch(getApplications(userId, currentPage + 1, token));
  }, [currentPage]);

  // Set Cuurent Page Number to current - 1 if Next page url is null and applications lenght == 10
  useEffect(() => {
    if (
      applications?.data?.length == 10 &&
      !applications?.next_page_url &&
      currentPage !== 0
    ) {
      setCurrentPage(currentPage - 1);
    }
  }, [applications?.data?.length]);

  // Loading
  if (loading) {
    return (
      <div className="flex items-center justify-center h-[calc(100vh-90px)]">
        <AnimatedLoading />
      </div>
    );
  }
  return (
    <div>
      <h3 className=" py-6 text-[20px] capitalize lg:text-[28px] font-figtree leading-8 font-bold text-black 2xl:w-[1300px] 2xl:mx-auto">
        Applications
      </h3>
      <div className="grid grid-cols-1">
        <div className="transition px-2 md:px-4 ;g:px-6 py-6 flex flex-col w-full bg-white  2xl:w-[1300px] 2xl:mx-auto rounded-[12px]">
          <div className="flex items-center">
            <img
              src={ApplicationIcon}
              loading="lazy"
              alt="icon"
              className="h-[26px] w-[26px] mr-3 cover"
            />
            <h5 className="text-[16px] leading-6 font-figtree font-medium text-blueDacker">
              Applications
            </h5>
          </div>
          {applications?.data?.length ? (
            <div className=" grid grid-cols-1  pt-4">
              {/* Application table */}
              <ApplicationTable
                applicationsData={applications?.data}
                page={currentPage + 1}
              />
              {/* Pagination Component */}
              {applications?.total > 10 && (
                <Pagination
                  itemsPerPage={applications?.per_page}
                  totalPages={applications?.total / 10}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              )}
            </div>
          ) : (
            <div className="text-center flex items-center flex-col h-full justify-center text-dark">
              <NoRecord
                title={`Sorry, we couldn't find any applications data.`}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Applications;
