import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import AuthReducer from 'redux/reducers/AuthReducer';
import UserReducer from 'redux/reducers/UserReducer';
import AdminReducer from 'redux/reducers/AdminReducer';
import NotificationReducer from 'redux/reducers/NotificationReducer';
import * as ActionTypes from 'redux/actions/ActionTypes/ActionTypes';

const persistConfig = {
  key: 'miaimy-client',
  storage,
};

const allReducers = combineReducers({
  auth: AuthReducer,
  user: UserReducer,
  admin: AdminReducer,
  notification: NotificationReducer,
});
const rootReducer = (state, action) => {
  if (action.type === ActionTypes.LOGOUT_USER) {
    state = undefined;
  }
  return allReducers(state, action);
};

const middleware = [thunk];

let store = createStore(
  persistReducer(persistConfig, rootReducer),
  composeWithDevTools(applyMiddleware(...middleware))
);
store.__PERSISTOR = persistStore(store);

export default store;
