import * as ActionTypes from 'redux/actions/ActionTypes/ActionTypes';

export const setNewUserNotification = () => {
  return {
    type: ActionTypes.SET_NEW_USER_NOTIFICATION,
  };
};
export const clearNotificationCount = () => {
  return {
    type: ActionTypes.CLEAR_NOTIFICATIONS,
  };
};
