import { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import { adminDeleteUser } from 'redux/actions/ActionCreators/AdminActions';
import classes from './Modal.module.css';

export const TheDeleteConfirmationModal = (props) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.auth?.user?.access_token);

  // Handler For Delete Student
  const handleDelete = () => {
    dispatch(adminDeleteUser(props?.id, props?.page, token));
  };
  return (
    <div>
      <div className={classes.backdrop}>
        <div className={`${classes.modal} ${classes.card} h-auto`}>
          <div className={`h-[auto]  px-4 py-6`}>
            <h5 className="text-red-500 text-center text-[18px] font-figtree font-medium">
              Please Confirm This!
            </h5>
            <p className="text-dark text-center text-[16px] font-figtree font-normal">
              This will remove all data related to this student permanently and
              this process will not be reverseable.
            </p>
            <div className="flex items-center justify-center md:justify-end px-4 mt-4">
              <button
                className="relative mr-4 block w-[120px] h-[30px] md:h-[36px] bg-lightgray rounded-[3px] text-white font-figtree font-medium text-centerrelative  items-center justify-start  text-[14px]  text-center  px-2  boder-0 outline-none  overflow-hidden  transition-all   hover:bg-white hover:border border-lightgray group"
                onClick={() => props.onConfirm()}
              >
                <span className="absolute inset-0 border-0 group-hover:border-[25px] ease-linear duration-100 transition-all border-white rounded-[6px]"></span>
                <span className="relative w-full text-center text-gray transition-colors duration-200 ease-in-out group-hover:text-gray">
                  Cancel
                </span>
              </button>
              <button
                onClick={() => handleDelete()}
                className="relative block w-[120px] h-[30px] md:h-[36px] bg-red-500 rounded-[3px] text-white font-figtree font-medium text-centerrelative  items-center justify-start  text-[14px]  text-center  px-2  boder-0 outline-none  overflow-hidden  transition-all   hover:bg-white hover:border border-red-500 group"
              >
                <span className="absolute inset-0 border-0 group-hover:border-[25px] ease-linear duration-100 transition-all border-white rounded-[6px]"></span>
                <span className="relative  w-full text-center text-white transition-colors duration-200 ease-in-out group-hover:text-red-500">
                  Delete
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DeleteConfirmationModal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <TheDeleteConfirmationModal
          onConfirm={props.onConfirm}
          id={props?.id}
          page={props?.page}
        />,
        document.getElementById('modal')
      )}
    </Fragment>
  );
};
