import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AppointmentIcon from 'assets/images/appoint-icon.png';
import NoRecord from 'components/norecord/NoRecord';
import AppointmentsTable from './AppointmentsTable';

const AppointmentsCard = () => {
  const appointments = useSelector((state) => state?.user?.appointments);
  const navigate = useNavigate();

  return (
    <div className="transition px-2 md:px-4 lg:px-6 py-6 flex flex-col w-full lg:relative bg-white  2xl:w-[982px] lg:h-[350px] rounded-[12px]">
      <div className="flex items-center">
        <img
          src={AppointmentIcon}
          loading="lazy"
          alt="icon"
          className="h-[16px] w-[16px] mr-3"
        />
        <h5 className="text-[16px] leading-6 font-figtree font-medium text-blueDacker">
          Upcoming Appointments
        </h5>
      </div>
      {appointments && appointments?.data?.length ? (
        <div className="overflow-y-scroll custom-scroll-bar overflow-x-hidden pt-3">
          {/* Appointments Table  will show only 4 appointments in dashboard*/}
          <AppointmentsTable
            appointmentsData={appointments?.data?.slice(0, 3)}
          />
        </div>
      ) : (
        <div className="text-center flex items-center flex-col h-full justify-center text-dark">
          <NoRecord title={`Sorry, we couldn't find any appointments data.`} />
        </div>
      )}
      {appointments?.data?.length ? (
        <p
          className="text-[12px] text-primary lg:absolute lg:bottom-3 lg:right-4 mt-3 text-right cursor-pointer"
          onClick={() => navigate('/appointments')}
        >
          Click Here To View All Appointments
        </p>
      ) : (
        ''
      )}
    </div>
  );
};

export default AppointmentsCard;
