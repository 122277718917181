import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { MdOutlineReadMore } from 'react-icons/md';
import { BiSolidEdit } from 'react-icons/bi';
import { BsCheckCircleFill } from 'react-icons/bs';
import { toast } from 'react-toastify';

import Spinner from 'components/spinner/Spinner';
import {
  adminApprovedAppointmentStatus,
  adminCreateMeeting,
  adminLaunchMeeting,
  adminDeleteUserAppointment,
} from 'redux/actions/ActionCreators/AdminActions';
import { AppointmentModal } from 'components/modal/AppointmentModal';
import { AddAppointmentFromStudentDetails } from 'components/modal/AddAppointmentFromStudentDetails';

const AdminAppointmentsTable = ({
  appointmentsData,
  renderStudentDeatils,
  page,
  userName,
}) => {
  const dispatch = useDispatch();
  const param = useParams();
  const navigate = useNavigate();

  const token = useSelector((state) => state?.auth?.user?.access_token);
  const loading = useSelector((state) => state?.user?.loading);

  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [appointmentModalStudentDetails, setappointmentModalStudentDetails] =
    useState(false);

  const [appointmentId, setApppointmentId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  // Set Cuurent Page Number
  useEffect(() => {
    if (page) {
      if (page == 1) {
        setCurrentPage(page);
      } else if (page > 1 && appointmentsData.length > 1) {
        setCurrentPage(page);
      } else {
        setCurrentPage(page - 1);
      }
    }
  }, [page, appointmentsData.length]);

  // Handler For Delete Appointment
  const handleDeleteAppointment = (id) => {
    dispatch(adminDeleteUserAppointment(id, currentPage, token));
    // For Student Details Page
    if (param?.id) {
      renderStudentDeatils(true);
    }
  };

  // Handler For Confirm Appointment
  const handleConfirmAppointment = (id) => {
    let currPage = currentPage;
    if (appointmentsData.length == 1 && page > 1) {
      currPage = currentPage + 1;
    }
    dispatch(adminApprovedAppointmentStatus(id, currPage, token));
    // For Student Details Page
    if (param?.id) {
      renderStudentDeatils(true);
    }
  };

  // Handler For Create Zoom Meetings
  const handleCreateZoomMeeting = (id, status) => {
    // Set Page
    let currPage = currentPage;
    if (appointmentsData.length == 1 && page > 1) {
      currPage = currentPage + 1;
    }
    // check appointment's status
    if (status == 1) {
      dispatch(adminCreateMeeting(id, currPage, token));
      // For Student Details Page
      if (param?.id) {
        renderStudentDeatils(true);
      }
    } else {
      toast.error('Appointment Not Approved');
    }
  };

  // Handler For Launch Zoom Meeting
  const handleLaunchMeeting = (id) => {
    let currPage = currentPage;
    if (appointmentsData.length == 1 && page > 1) {
      currPage = currentPage + 1;
    }
    dispatch(adminLaunchMeeting(id, currPage, token));
    // For Studnets Deatils Page
    if (param?.id) {
      renderStudentDeatils(true);
    }
  };

  // Loading
  if (loading) {
    return (
      <div className="flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  // Handler on confirm Close modal
  const handleConfirm = () => {
    setShowAppointmentModal(false);
    setappointmentModalStudentDetails(false);
    setApppointmentId(null);
  };
  // Handler on edit  appointment
  const handleEditAppointment = (data) => {
    if (!param?.id) {
      setShowAppointmentModal(true);
      setApppointmentId(data?.id);
    } else {
      setappointmentModalStudentDetails(true);
      setApppointmentId(data?.id);
    }
  };

  return (
    <div className="flex flex-col px-2">
      <div className="sm:-mx-6 lg:-mx-8">
        <div className=" min-w-full py-2  lg:px-4">
          <div className="overflow-x-auto custom-scroll-bar">
            <table className="min-w-full  text-left text-sm font-light">
              <thead className="whitespace-nowrap	 bg-successLight font-medium ">
                <tr>
                  {!param?.id && (
                    <th
                      scope="col"
                      className="px-6 py-4 text-success text-[14px] font-figtree font-bold leading-5"
                    >
                      User Id
                    </th>
                  )}
                  {!param?.id && (
                    <th
                      scope="col"
                      className="px-6 py-4 text-success text-[14px] font-figtree font-bold leading-5"
                    >
                      User
                    </th>
                  )}
                  <th
                    scope="col"
                    className="px-6 flex w-max py-4 text-success text-[14px] font-figtree font-bold leading-5"
                  >
                    Consultant Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 text-success text-[14px] font-figtree font-bold leading-5"
                  >
                    Consultancy Agenda
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 text-success text-[14px] font-figtree font-bold leading-5"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 text-success text-[14px] font-figtree font-bold leading-5"
                  >
                    Time
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 text-success text-center text-[14px] font-figtree font-bold leading-5"
                  >
                    Status
                  </th>

                  <th
                    scope="col"
                    className={`text-start px-6 py-4  text-success text-[14px] font-figtree font-bold leading-5`}
                  >
                    Actions
                  </th>
                  <th
                    scope="col"
                    className={`text-start px-6 py-4  text-success text-[14px] font-figtree font-bold leading-5`}
                  >
                    Meetings
                  </th>
                </tr>
              </thead>
              <tbody>
                {appointmentsData?.map((data, index) => (
                  <tr className="border-b border-[#E2E2EA]" key={index}>
                    {!param?.id && (
                      <td className="whitespace-nowrap capitalize px-6 py-4 text-dark text-[14px] leading-5 font-figtree font-normal">
                        {data?.user?.id}
                      </td>
                    )}
                    {!param?.id && (
                      <td className="whitespace-nowrap capitalize px-6 py-4 text-dark text-[14px] leading-5 font-figtree font-normal">
                        {data?.user?.name}
                      </td>
                    )}
                    <td className="whitespace-nowrap capitalize px-6 py-4 text-dark text-[14px] leading-5 font-figtree font-normal">
                      {data?.consultant_name}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-dark text-[14px] leading-5 font-figtree font-normal">
                      {data?.agenda}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-dark text-[14px] leading-5 font-figtree font-normal">
                      {moment(data?.date).format('MMMM DD, YYYY')}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-dark text-[14px] leading-5 font-figtree font-normal">
                      {moment(data?.time, 'HH:mm:ss').format('hh:mm A')}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-dark text-[14px] leading-5 font-figtree font-normal">
                      {data?.status == 1 ? (
                        <p className="bg-successLight text-success text-[12px] font-medium font-figtree text-center py-1 px-2 rounded">
                          Approved
                        </p>
                      ) : (
                        <p className="bg-red-100 text-red-600 text-[12px] font-medium font-figtree text-center py-1 px-2 rounded">
                          Pending
                        </p>
                      )}
                    </td>
                    <td
                      className={`justify-start whitespace-nowrap flex  items-center px-6 py-4 text-dark text-[14px] leading-5 font-figtree font-normal`}
                    >
                      <AiOutlineDelete
                        onClick={() => handleDeleteAppointment(data?.id)}
                        size={20}
                        className="text-gray cursor-pointer"
                      />

                      <BiSolidEdit
                        size={20}
                        className="text-success flex mx-2 justify-center cursor-pointer"
                        onClick={() => handleEditAppointment(data)}
                      />

                      {!param?.id && (
                        <MdOutlineReadMore
                          size={25}
                          className="text-primary flex  justify-center cursor-pointer"
                          onClick={() =>
                            navigate(`/students/${data?.user?.id}`)
                          }
                        />
                      )}
                      {!param?.id && data.status == 0 && (
                        <BsCheckCircleFill
                          size={17}
                          onClick={() => handleConfirmAppointment(data?.id)}
                          className="text-success ml-2 flex  justify-center cursor-pointer"
                        />
                      )}
                    </td>
                    <td>
                      {!data?.join_url ? (
                        <button
                          className="relative block mx-auto w-[120px] h-[30px] md:h-[30px] bg-lightgray rounded-[3px] text-white font-figtree font-medium text-centerrelative  items-center justify-start  text-[12px]  text-center  px-2  boder-0 outline-none  overflow-hidden  transition-all   hover:bg-white hover:border border-lightgray group"
                          onClick={() =>
                            handleCreateZoomMeeting(data?.id, data?.status)
                          }
                        >
                          <span className="absolute inset-0 border-0 group-hover:border-[25px] ease-linear duration-100 transition-all border-white rounded-[6px]"></span>
                          <span className="relative w-full text-center text-gray transition-colors duration-200 ease-in-out group-hover:text-gray">
                            Create Meeting
                          </span>
                        </button>
                      ) : (
                        <button
                          className="relative block mx-auto w-[120px] h-[30px] md:h-[30px] bg-success rounded-[3px] text-white font-figtree font-medium text-centerrelative  items-center justify-start  text-[12px]  text-center  px-2  boder-0 outline-none  overflow-hidden  transition-all   hover:bg-white hover:border border-success group"
                          onClick={() => handleLaunchMeeting(data?.id)}
                        >
                          <span className="absolute inset-0 border-0 group-hover:border-[25px] ease-linear duration-100 transition-all border-white rounded-[6px]"></span>
                          <span className="relative w-full text-center text-white transition-colors duration-200 ease-in-out group-hover:text-success">
                            Launch Meeting
                          </span>
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* Add/Edit  Appointment Modal */}
            {showAppointmentModal && (
              <AppointmentModal
                onConfirm={handleConfirm}
                id={appointmentId}
                page={currentPage}
              />
            )}
            {/* Add/Edit  Appointment Modal Students Details */}
            {appointmentModalStudentDetails && (
              <AddAppointmentFromStudentDetails
                onConfirm={handleConfirm}
                id={appointmentId}
                renderStudentDeatils={renderStudentDeatils}
                userName={userName}
                appointments={appointmentsData}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminAppointmentsTable;
