import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import DocumentsHeader from "./DocumentsHeader";
import DocumentsField from "components/textfield/DocumentsField";
import Spinner from "components/spinner/Spinner";
import { DocsViewModal } from "components/modal/DocsViewModal";
import { addUserDocument } from "redux/actions/ActionCreators/UserActions";
import axios from "axios";
import { config } from "helpers/config/Config";

const UploadDocuments = ({
  id,
  title,
  current,
  setActiveTab,
  matchedDocument,
}) => {
  var [file, setFile] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [documentLoading, setDocumentLoading] = useState(false);
  const [documentData, setDocumentData] = useState(null);

  const loading = useSelector((state) => state?.user?.loading);
  const token = useSelector((state) => state?.auth?.user.access_token);
  const dispatch = useDispatch();

  // Initial Values
  let initialValues = {
    name: id,
    file: "",
  };

  // Error Schema
  const errorSchema = Yup.object().shape({
    name: Yup.string().required("Document Name is required"),
    file: Yup.mixed().required("File is required"),
  });
  // Form Submission
  const handleSubmit = (values, { resetForm }) => {
    let formData = new FormData();
    formData.append("file", values.file);
    formData.append("doc_name", values?.name);
    dispatch(addUserDocument(formData, setActiveTab, current, token));
  };

  const handleModalShow = () => {
    setShowModal(false);
  };

  const handleViewDocument = () => {
    // setDocument(data);
    getDocumentFile(matchedDocument);
  };

  const getDocumentFile = async (documentData) => {
    try {
      setDocumentLoading(true);
      setShowModal(true);
      let payloadDat = {
        document: documentData?.file,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/get-document`,
        payloadDat,
        config(token)
      );
      setDocumentData(response?.data);
      setDocumentLoading(false);
    } catch (error) {
      setDocumentLoading(false);
      console.log(error);
    }
  };

  return (
    <div className="md:relative h-[350px] md:h-[300px]">
      {/* Documents View Modal */}
      {showModal && (
        <DocsViewModal
          onConfirm={handleModalShow}
          documentData={documentData}
          isLoading={documentLoading}
        />
      )}
      <DocumentsHeader title={title} text={"Upload Your Documents"} />
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={errorSchema}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <Form className="w-full">
              <DocumentsField
                name="file"
                label="Document"
                value={undefined}
                onChange={(e) => {
                  props.setFieldValue("file", e.target.files[0]);
                  setFile(e.target.files[0]);
                }}
              />
              <div className="flex justify-end border-t w-full md:absolute bottom-5  border-t-lightgray">
                <button className="relative mx-auto lg:mx-0 my-3 w-[200px] h-[36px] md:h-[44px] bg-success rounded-[3px] text-white font-figtree font-medium text-centerrelative  items-center justify-start block text-[14px]  text-center  px-4  boder-0 outline-none  overflow-hidden  transition-all   hover:bg-white hover:border border-success group">
                  <span className="absolute inset-0 border-0 group-hover:border-[25px] ease-linear duration-100 transition-all border-white rounded-[6px]"></span>
                  <span className="relative w-full text-center text-white transition-colors duration-200 ease-in-out group-hover:text-success">
                    {loading ? <Spinner /> : "Save"}
                  </span>
                </button>
              </div>
            </Form>
          )}
        </Formik>
        {matchedDocument && (
          <p
            className="text-blueDacker border-b border-b-blueDacker w-fit font-normal font-figtree text-[13px] md:text-[14px] mb-0 mt-2 md:mt-4 cursor-pointer capitalize"
            onClick={() => handleViewDocument()}
          >
            {matchedDocument?.doc_name}.{matchedDocument?.doc_type}
          </p>
        )}
      </div>
    </div>
  );
};

export default UploadDocuments;
