import { Fragment, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { RiCloseCircleFill } from "react-icons/ri";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import classes from "./Modal.module.css";
import TextField from "components/textfield/TextField";
import Spinner from "components/spinner/Spinner";
import { adminAddStudent } from "redux/actions/ActionCreators/AdminActions";
import DetectClickOutside from "modules/DetectClickOutside";

export const TheStudentModal = (props) => {
  const clickRef = useRef("");
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.user.access_token);
  const loading = useSelector((state) => state.user.loading);

  // Sign Up Form Initial Values
  const initialValues = {
    name: "",
    email: "",
    password: "",
  };
  // Error Schema
  const errorSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email().required("Email is required"),
    password: Yup.string().required("Password  is required"),
  });
  // Form Submission
  const handleSubmit = (values, { resetForm }) => {
    let data = {
      name: values.name,
      email: values.email,
      password: values.password,
    };
    dispatch(adminAddStudent(data, props?.page, token));
    props.onConfirm();
    resetForm({
      values: "",
    });
  };
  return (
    <div>
      <div className={classes.backdrop}>
        <div
          className={`${classes.modal} ${classes.card} 2xl:w-fit`}
          ref={clickRef}
        >
          {/* // Detect Click Outside the Modal */}
          <DetectClickOutside clickRef={clickRef} onConfirm={props.onConfirm} />
          <header
            className={`${classes.header} flex items-center justify-between `}
          >
            <h3 className=" text-[16px] lg:text-[24px] font-figtree leading-8 font-medium text-white ">
              Add Student
            </h3>
            <RiCloseCircleFill
              size={25}
              className="text-white cursor-pointer"
              onClick={props.onConfirm}
            />
          </header>
          <div className={classes.content}>
            <Formik
              initialValues={initialValues}
              validationSchema={errorSchema}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <Form className="w-full " autoComplete="off">
                  <TextField
                    type="text"
                    label="Full Name"
                    name="name"
                    placeholder="Enter name"
                  />
                  <TextField
                    type="email"
                    label="Email Address"
                    name="email"
                    placeholder="Enter email"
                  />
                  <TextField
                    type="password"
                    label="Password"
                    name="password"
                    placeholder="Enter password"
                  />
                  {/* Sign In Button  */}
                  <button className="relative  inline-flex items-center justify-start inline-block  text-white text-[14px] font-jakarta-sans font-medium w-full xl:w-full 2xl:w-[436px] h-[44px] text-center  px-4  boder-0 outline-none  overflow-hidden  transition-all bg-success rounded-[6px] hover:bg-white hover:border border-success group">
                    <span className="absolute inset-0 border-0 group-hover:border-[25px] ease-linear duration-100 transition-all border-white rounded-[6px]"></span>
                    <span className="relative w-full text-center text-white transition-colors duration-200 ease-in-out group-hover:text-success">
                      {loading ? <Spinner /> : "Add Student"}
                    </span>
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export const StudentModal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <TheStudentModal onConfirm={props.onConfirm} page={props?.page} />,
        document.getElementById("modal")
      )}
    </Fragment>
  );
};
