import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ApplicationIcon from "assets/images/application-icon.png";
import Search from "modules/Search";
import AdminApplicationTable from "admin/components/applications/AdminApplicationsTable";
import NoRecord from "components/norecord/NoRecord";
import Pagination from "modules/Pagination";
import { ApplicationModal } from "components/modal/ApplicationModal";
import {
  adminGetApplications,
  getAllStudentsList,
} from "redux/actions/ActionCreators/AdminActions";
import AnimatedLoading from "components/spinner/AnimatedLoading";

const Applications = () => {
  const dispatch = useDispatch();

  const token = useSelector((state) => state?.auth?.user?.access_token);
  const loading = useSelector((state) => state?.user?.loading);
  const applications = useSelector((state) => state?.admin?.applications);

  const [currentPage, setCurrentPage] = useState(0);
  const [showApplicationModal, setShowApplicationModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  // Handler On Confirm Modal Close
  const handleConfirm = () => {
    setShowApplicationModal(false);
  };

  // Get All Students List
  useEffect(() => {
    dispatch(getAllStudentsList(token));
  }, []);

  // Get Applications List
  useEffect(() => {
    dispatch(adminGetApplications(currentPage + 1, searchQuery, token));
  }, [currentPage]);

  // Set Cuurent Page Number to current - 1 if Next page url is null and applications lenght == 10
  useEffect(() => {
    if (
      applications?.data?.length == 10 &&
      !applications?.next_page_url &&
      currentPage !== 0
    ) {
      setCurrentPage(currentPage - 1);
    }
  }, [applications?.data?.length]);

  // Loading
  if (loading) {
    return (
      <div className="flex items-center justify-center h-[calc(100vh-90px)]">
        <AnimatedLoading />
      </div>
    );
  }

  // Handle Search
  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(adminGetApplications(0, searchQuery, token));
  };
  return (
    <div className="min-h-full">
      <p className="py-6 text-[20px] lg:text-[28px] font-figtree leading-8 font-bold text-black 2xl:w-[1300px] 2xl:mx-auto">
        Applications List
      </p>
      <div className="grid grid-cols-1">
        <div className="transition px-2 md:px-4 ;g:px-6 py-6 flex flex-col w-full bg-white  2xl:w-[1300px] 2xl:mx-auto rounded-[12px]">
          <div className="flex items-center">
            <img
              src={ApplicationIcon}
              loading="lazy"
              alt="icon"
              className="h-[26px] w-[26px] mr-3 cover"
            />
            <h5 className="text-[16px] leading-6 font-figtree font-medium text-blueDacker">
              Applications
            </h5>
          </div>
          <Search
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleSearch={handleSearch}
          />
          {applications?.data?.length ? (
            <div className=" w-full pt-4">
              {/* Applications Table */}
              <AdminApplicationTable
                applicationsData={applications?.data}
                page={currentPage + 1}
              />
              {/* Pagination component */}
              {applications?.total > 10 && (
                <Pagination
                  itemsPerPage={applications?.per_page}
                  totalPages={applications?.total / 10}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              )}
            </div>
          ) : (
            <NoRecord
              title={`Sorry, we couldn't find any applications data.`}
            />
          )}
          {/* Add Application Modal */}
          {showApplicationModal && (
            <ApplicationModal
              onConfirm={handleConfirm}
              page={currentPage + 1}
            />
          )}

          <div className="flex justify-end items-center">
            <button
              className="relative mx-auto lg:mx-0 mt-3 w-[150px] h-[36px] md:h-[44px] bg-success rounded-[3px] text-white font-figtree font-medium text-centerrelative  inline-flex items-center justify-start inline-block text-[14px]  text-center  px-4  boder-0 outline-none  overflow-hidden  transition-all   hover:bg-white hover:border border-success group"
              onClick={() => setShowApplicationModal(true)}
            >
              <span className="absolute inset-0 border-0 group-hover:border-[25px] ease-linear duration-100 transition-all border-white rounded-[6px]"></span>
              <span className="relative w-full text-center text-white transition-colors duration-200 ease-in-out group-hover:text-success">
                Add Application
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Applications;
