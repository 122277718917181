import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Logo from 'assets/images/logo.png';
import TextField from 'components/textfield/TextField';
import { resetPassword } from 'redux/actions/ActionCreators/AuthActions';
import Spinner from 'components/spinner/Spinner';

const ResetPassword = () => {
  const loading = useSelector((state) => state?.auth?.loading);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Form Initial Values
  const initialValues = {
    otp: '',
    password: '',
    email: '',
    confirmPassword: '',
  };
  // Error Schema
  const errorSchema = Yup.object().shape({
    otp: Yup.string().required('Otp  is required'),
    password: Yup.string().email().required('Email  is required'),
    password: Yup.string().required('Password  is required'),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must match'
    ),
  });
  // Form Submission
  const handleSubmit = (values, { resetForm }) => {
    let data = {
      password: values.password,
      otp: values.otp,
      email: values.email,
    };
    dispatch(resetPassword(data, navigate));
    resetForm({
      values: '',
    });
  };
  return (
    <div className="flex items-center flex-col rounded-[12px] bg-[#ffffff] px-6  md:px-6 lg:px-8 py-6  2xl:w-[500px] ">
      <img
        src={Logo}
        loading="lazy"
        alt="logo"
        className=" w-[130px] md:w-[150px] h-full mb-2 lg:mb-3 xl:mb-5 lg:w-[146px] lg:h-[24px]"
      />
      <h3 className=" text-[20px] md:text-[25px] lg:text-[28px] leading-[30px] lg:leading-[32px] font-jakarta-sans text-dark font-bold mb-2 lg:mb-2">
        Reset your password
      </h3>
      <p className="text-[14px] leading-[20px] font-jakarta-sans font-medium text-dark">
        Please enter your registered email address
      </p>
      <Formik
        initialValues={initialValues}
        validationSchema={errorSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form className="w-full">
            <TextField
              type="text"
              label="OTP Code"
              name="otp"
              placeholder="Enter your otp code"
            />
            <TextField
              type="email"
              label="Registered Email"
              name="email"
              placeholder="Enter your email"
            />
            <TextField
              type="password"
              label="New Password"
              name="password"
              placeholder="Enter your new password"
            />
            <TextField
              type="password"
              label="Confirm Password"
              name="confirmPassword"
              placeholder="confirm your new password"
            />
            {/* Submit Button  */}
            <button
              type="submit"
              className="relative  inline-flex items-center justify-start inline-block  text-white text-[14px] font-jakarta-sans font-medium w-full xl:w-full 2xl:w-[436px] h-[44px] text-center  px-4    boder-0 outline-none  overflow-hidden  transition-all bg-success rounded-[6px] hover:bg-white hover:border border-success group"
            >
              <span className="absolute inset-0 border-0 group-hover:border-[25px] ease-linear duration-100 transition-all border-white rounded-[6px]"></span>
              <span className="relative w-full text-center text-white transition-colors duration-200 ease-in-out group-hover:text-success">
                {loading ? <Spinner /> : ' Submit'}
              </span>
            </button>
          </Form>
        )}
      </Formik>
      <div className="flex justify-end items-end mt-2">
        <p
          className=" text-primary text-[14px] leading-[20px] font-jakarta-sans font-medium cursor-pointer"
          onClick={() => navigate('/login')}
        >
          Login or Signup?
        </p>
      </div>
    </div>
  );
};

export default ResetPassword;
