import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';

import { deleteUserAppointment } from 'redux/actions/ActionCreators/UserActions';
import Spinner from 'components/spinner/Spinner';

const AppointmentsTable = ({ appointmentsData, page }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.auth?.user?.access_token);
  const loading = useSelector((state) => state?.user?.loading);
  const [currentPage, setCurrentPage] = useState(1);

  // Set Current Page Number
  useEffect(() => {
    if (page) {
      if (page == 1) {
        setCurrentPage(page);
      } else if (page > 1 && appointmentsData.length > 1) {
        setCurrentPage(page);
      } else {
        setCurrentPage(page - 1);
      }
    }
  }, [page, appointmentsData.length]);

  // Handler for Delete Appointment
  const handleDeleteAppointment = (id) => {
    dispatch(deleteUserAppointment(id, currentPage, token));
  };

  // Loading
  if (loading) {
    return (
      <div className="flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col px-2">
      <div className="sm:-mx-6 lg:-mx-8">
        <div className=" min-w-full py-2  lg:px-4">
          <div className="overflow-x-auto custom-scroll-bar">
            <table className="min-w-full  text-left text-sm font-light">
              <thead className="whitespace-nowrap	 bg-successLight font-medium ">
                <tr>
                  <th
                    scope="col"
                    className="px-6 flex w-max py-4 text-success text-[14px] font-figtree font-bold leading-5"
                  >
                    Consultant Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 text-success text-[14px] font-figtree font-bold leading-5"
                  >
                    Consultancy Agenda
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 text-success text-[14px] font-figtree font-bold leading-5"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 text-success text-[14px] font-figtree font-bold leading-5"
                  >
                    Time
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 text-success text-center text-[14px] font-figtree font-bold leading-5"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className={`text-center px-6 py-4  text-success text-[14px] font-figtree font-bold leading-5`}
                  >
                    Actions
                  </th>
                  <th
                    scope="col"
                    className={`text-center px-6 py-4  text-success text-[14px] font-figtree font-bold leading-5`}
                  >
                    Meetings
                  </th>
                </tr>
              </thead>
              <tbody>
                {appointmentsData?.map((data, index) => (
                  <tr className="border-b border-[#E2E2EA]" key={index}>
                    <td className="whitespace-nowrap capitalize px-6 py-4 text-dark text-[14px] leading-5 font-figtree font-normal">
                      {data?.consultant_name}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-dark text-[14px] leading-5 font-figtree font-normal">
                      {data?.agenda}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-dark text-[14px] leading-5 font-figtree font-normal">
                      {moment(data?.date).format('MMMM DD, YYYY')}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-dark text-[14px] leading-5 font-figtree font-normal">
                      {moment(data?.time, 'HH:mm:ss').format('hh:mm A')}
                    </td>
                    <td className="whitespace-nowrap px-6 py-4 text-dark text-[14px] leading-5 font-figtree font-normal">
                      {data?.status ? (
                        <p className="bg-successLight text-success text-[12px] font-medium font-figtree text-center py-1 px-2 rounded">
                          Approved
                        </p>
                      ) : (
                        <p className="bg-red-100 text-red-600 text-[12px] font-medium font-figtree text-center py-1 px-2 rounded">
                          Pending
                        </p>
                      )}
                    </td>
                    <td
                      className={`justify-center whitespace-nowrap flex  items-center px-6 py-4 text-dark text-[14px] leading-5 font-figtree font-normal`}
                    >
                      <AiOutlineDelete
                        onClick={() => handleDeleteAppointment(data?.id)}
                        size={20}
                        className="text-gray cursor-pointer"
                      />
                    </td>

                    <td>
                      {data?.join_url ? (
                        <button
                          className="relative block mx-auto w-[100px] h-[30px] md:h-[30px] bg-success rounded-[3px] text-white font-figtree font-medium text-centerrelative  items-center justify-start  text-[12px]  text-center  px-2  boder-0 outline-none  overflow-hidden  transition-all   hover:bg-white hover:border border-success group"
                          onClick={() => window.open(data?.join_url)}
                        >
                          <span className="absolute inset-0 border-0 group-hover:border-[25px] ease-linear duration-100 transition-all border-white rounded-[6px]"></span>
                          <span className="relative w-full text-center text-white  transition-colors duration-200 ease-in-out group-hover:text-success">
                            Join Meeting
                          </span>
                        </button>
                      ) : (
                        <button
                          className=" block mx-auto text-[12px] w-[100px] h-[30px] md:h-[30px] bg-lightgray rounded-[3px] text-gray  font-figtree font-medium text-center"
                          disabled
                          onClick={() => window.open(data?.join_url)}
                        >
                          Pending
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentsTable;
