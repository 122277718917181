import React, { useState } from "react";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { BsCloudDownload } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { config } from "helpers/config/Config";
import moment from "moment";
import axios from "axios";

import { deleteUserdocuments } from "redux/actions/ActionCreators/UserActions";
import { DocsViewModal } from "components/modal/DocsViewModal";
import Spinner from "components/spinner/Spinner";



const DocumentsTable = ({ documentsData, renderStudentDeatils }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.auth?.user?.access_token);
  const role = useSelector((state) => state?.auth?.user?.role);
  const loading = useSelector((state) => state?.user?.loading);
  const isAdmin = role === "admin" ? true : false;

  const [showModal, setShowModal] = useState(false);
  const [documents, setDocument] = useState(null);
  const [documentLoading, setDocumentLoading] = useState(false);

  // Hanlder For Delete Documents
  const handleDeleteDocument = (id) => {
    dispatch(deleteUserdocuments(id, token));
    if (isAdmin) {
      renderStudentDeatils(true);
    }
  };

  const handleViewDocument = (documentData) => {
    // setDocument(data);
    getDocumentFile(documentData);
  };

  // Handler For Download Documents
  const downloadFileUrl = (url, type, name) => {
    let fileName = `${name}.${type}`;
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${fileName}`;
        link.click();
      })
      .catch(console.error);
  };

  // Handler for Doument Modal show/hide
  const handleModalShow = () => {
    setShowModal(false);
  };

  const getDocumentFile = async (documentData) => {
    try {
      setDocumentLoading(true);
      setShowModal(true);
      let payloadDat = {
        document: documentData?.file,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/get-document`,
        payloadDat,
        config(token)
      );
      setDocument(response?.data);
      setDocumentLoading(false);
    } catch (error) {
      setDocumentLoading(false);
      console.log(error);
    }
  };

  // Loading
  if (loading) {
    return (
      <div className="flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col px-2 w-full ">
      {/* Documents View Modal */}
      {showModal && (
        <DocsViewModal
          onConfirm={handleModalShow}
          documentData={documents}
          isLoading={documentLoading}
        />
      )}
      <div className="sm:-mx-6 lg:-mx-8">
        <div className=" min-w-full py-2  lg:px-4">
          <div className="overflow-x-auto custom-scroll-bar">
            <table className="min-w-full  text-left text-sm font-light">
              <thead className="whitespace-nowrap	bg-successLight font-medium ">
                <tr>
                  <th
                    scope="col"
                    className="px-4 flex w-max py-4 text-success text-[14px] font-figtree font-bold leading-5"
                  >
                    File Name
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 text-success text-[14px] font-figtree font-bold leading-5"
                  >
                    Date Uploaded
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 text-success text-center text-[14px] font-figtree font-bold leading-5"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {documentsData.map((data, index) => (
                  <tr className="border-b border-[#E2E2EA]" key={index}>
                    <td className="whitespace-nowrap px-4 py-4 text-dark text-[14px] leading-5 font-figtree font-normal">
                      <a href={data.url} target="_blank">
                        {data?.doc_name}
                      </a>
                    </td>
                    <td className="whitespace-nowrap px-4 py-4 text-dark text-[14px] leading-5 font-figtree font-normal">
                      {moment(data?.updated_at).format("MMMM DD, YYYY")}
                    </td>
                    <td className="whitespace-nowrap flex justify-center items-center px-4 py-4 text-dark text-[14px] leading-5 font-figtree font-normal">
                      <AiOutlineEye
                        size={20}
                        onClick={() => handleViewDocument(data)}
                        className="ml-3 text-primary cursor-pointer"
                      />

                      <AiOutlineDelete
                        onClick={() => handleDeleteDocument(data?.doc_id)}
                        size={20}
                        className="text-gray mx-2 cursor-pointer"
                      />
                      <BsCloudDownload
                        size={17}
                        className=" text-success cursor-pointer"
                        onClick={() =>
                          downloadFileUrl(
                            data?.url,
                            data?.doc_type,
                            data?.doc_name
                          )
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentsTable;
