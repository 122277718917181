import * as ActionTypes from '../ActionTypes/ActionTypes'


// Set Loading Ture
export const setLoadingTrue = () => (dispatch) => {
    dispatch({
        type: ActionTypes.SET_LOADING_TRUE
    })
}

// Set Loading False
export const setLoadingFalse = ()=>{
    return{
        type:ActionTypes.SET_LOADING_FALSE
    }
}