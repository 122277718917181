import React, { useState } from "react";
import { useSelector } from "react-redux";

import TabContent from "./TabContent";
import TabItem from "./TabItem";
import UploadDocuments from "./UploadDocuments";

// Options List For Document
let optionsList = [
  { id: "Matriculation", title: "Matriculation Mark sheet & Certificate" },
  { id: "Intermediate", title: "Intermediate Mark sheet & Certificate" },
  {
    id: "OLevels",
    title: "O Levels Levels Certificate",
  },
  {
    id: "ALevels",
    title: "A Levels Certificate",
  },
  { id: "BachelDegreeTranscript", title: "Bachelor Degree & Transcript" },
  { id: "MasterDegreeTranscript", title: "Master Degree & Transcript" },

  {
    id: "StatementOfPurpose",
    title: "Statement of Purpose – 700 to 800 words (Important for Admissions)",
  },
  { id: "ReferenceLetters", title: "Two Reference Letters" },
  { id: "RecommendationLetters", title: "Two Recommendation Letters" },

  { id: "ExperienceCertificate", title: "Experience Certificate(If any)" },
  { id: "EmployerReference", title: "Employer Reference (If any)" },

  { id: "IELTSTOEFLPTE", title: "IELTS/TOEFL/PTE" },
  { id: "CurriculumVitae", title: "Curriculum Vitae" },
  { id: "PassportScan", title: "Passport Scan (First 2 Pages)" },
  {
    id: "AccountMaintenanceLetterBankStatement",
    title: "Account Maintenance Letter and Bank Statement",
  },
  { id: "UKTBMedicalTest", title: "UKTB (Medical Test)" },
];

const DocumentsTabs = () => {
  const [activeTab, setActiveTab] = useState(0);
  const documents = useSelector((state) => state?.user?.documents);

  return (
    <div className="my-4 2xl:w-[1300px] 2xl:mx-auto transition ">
      <div className="flex gap-2 lg:gap-4">
        {/* Tab Items */}
        <div className="bg-white  rounded-[12px] w-fit lg:min-w-[300px] transition">
          <ul>
            {optionsList.length &&
              optionsList?.map((data, index) => {
                let matchDocument =
                  documents.length &&
                  documents.find((document) => document?.doc_name == data?.id);
                return (
                  <div key={index}>
                    <TabItem
                      title={data.title}
                      id={index}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      matchedDocument={matchDocument}
                    />
                  </div>
                );
              })}
          </ul>
        </div>
        {/* Tab Content */}
        <div className="bg-white py-4 h-[350px] md:h-[300px] px-4 rounded-[12px] w-full transition">
          {optionsList.length &&
            optionsList?.map((data, index) => {
              let matchDocument =
                documents.length &&
                documents.find((document) => document?.doc_name == data?.id);
              return (
                <TabContent id={index} activeTab={activeTab} key={index}>
                  <UploadDocuments
                    id={data?.id}
                    title={data?.title}
                    setActiveTab={setActiveTab}
                    current={index}
                    matchedDocument={matchDocument}
                  />
                </TabContent>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default DocumentsTabs;
