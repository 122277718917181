import React from 'react';
import { NavLink } from 'react-router-dom';
import { CgProfile } from 'react-icons/cg';
import { BsFillCloudUploadFill } from 'react-icons/bs';

const UserLinks = () => {
  return (
    <div className="py-6">
      {/* Nav Item Dashboard */}
      <NavLink to="/dashboard" activeclassname="navbar__link--active">
        <div className="flex items-center py-3 group cursor-pointer">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4 10H5C7.20914 10 9 11.7909 9 14V16C9 18.2091 7.20914 20 5 20H4C1.79086 20 0 18.2091 0 16V14C0 11.7909 1.79086 10 4 10ZM4 12C2.89543 12 2 12.8954 2 14V16C2 17.1046 2.89543 18 4 18H5C6.10457 18 7 17.1046 7 16V14C7 12.8954 6.10457 12 5 12H4ZM3.5 0C5.433 0 7 1.567 7 3.5V4.5C7 6.433 5.433 8 3.5 8C1.567 8 0 6.433 0 4.5V3.5C0 1.567 1.567 0 3.5 0ZM3.5 2C2.67157 2 2 2.67157 2 3.5V4.5C2 5.32843 2.67157 6 3.5 6C4.32843 6 5 5.32843 5 4.5V3.5C5 2.67157 4.32843 2 3.5 2ZM15 9H16C18.2091 9 20 10.7909 20 13V16C20 18.2091 18.2091 20 16 20H15C12.7909 20 11 18.2091 11 16V13C11 10.7909 12.7909 9 15 9ZM15 11C13.8954 11 13 11.8954 13 13V16C13 17.1046 13.8954 18 15 18H16C17.1046 18 18 17.1046 18 16V13C18 11.8954 17.1046 11 16 11H15ZM12.5 0H16.5C18.433 0 20 1.567 20 3.5C20 5.433 18.433 7 16.5 7H12.5C10.567 7 9 5.433 9 3.5C9 1.567 10.567 0 12.5 0ZM12.5 2C11.6716 2 11 2.67157 11 3.5C11 4.32843 11.6716 5 12.5 5H16.5C17.3284 5 18 4.32843 18 3.5C18 2.67157 17.3284 2 16.5 2H12.5Z"
              fill="#92929D"
              className="group-hover:fill-success"
            />
          </svg>
          <h5 className="ml-3 text-[14px] font-medium text-black leading-5 font-figtree group-hover:text-success group-hover:font-bold">
            Dashboard
          </h5>
        </div>
      </NavLink>
      {/* Nav Item Profile */}
      <NavLink to="/profile" activeclassname="navbar__link--active">
        <div className="flex items-center py-3 group cursor-pointer">
          <CgProfile
            size={22}
            className="text-[#92929D] group-hover:text-success"
          />

          <h5 className="ml-3 text-[14px] font-medium text-black leading-5 font-figtree group-hover:text-success group-hover:font-bold">
            Profile
          </h5>
        </div>
      </NavLink>
      {/* Nav Item Appointments */}
      <NavLink to="/appointments" activeclassname="navbar__link--active">
        <div className="flex items-center py-3 group cursor-pointer">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.3 11.7V8.3H2L2 11.7H6.3ZM8.3 11.7L18 11.7V8.3L8.3 8.3V11.7ZM6.3 2H3C2.44772 2 2 2.44772 2 3V6.3H6.3V2ZM8.3 2L8.3 6.3L18 6.3V3C18 2.44772 17.5523 2 17 2H8.3ZM6.3 18V13.7H2L2 17C2 17.5523 2.44772 18 3 18H6.3ZM8.3 18H17C17.5523 18 18 17.5523 18 17V13.7H8.3V18ZM0 17L0 3C0 1.34315 1.34315 0 3 0H17C18.6569 0 20 1.34315 20 3V17C20 18.6569 18.6569 20 17 20H3C1.34315 20 0 18.6569 0 17Z"
              fill="#92929D"
              className="group-hover:fill-success"
            />
          </svg>

          <h5 className="ml-3 text-[14px] font-medium text-black leading-5 font-figtree group-hover:text-success group-hover:font-bold">
            Appointments
          </h5>
        </div>
      </NavLink>

      {/* Nav Item Applications */}
      <NavLink to="/applications" activeclassname="navbar__link--active">
        <div className="flex items-center py-3 group cursor-pointer">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 0H12C13.1046 0 14 0.89543 14 2V9H18C19.1046 9 20 9.89543 20 11V19C20 19.5523 19.5523 20 19 20H13H7H1C0.447715 20 0 19.5523 0 19V6.5C0 5.39543 0.89543 4.5 2 4.5H6V2C6 0.89543 6.89543 0 8 0ZM8 6.5V18H12V11V2H8V6.5ZM6 6.5H2V18H6V6.5ZM14 18H18V11H14V18Z"
              fill="#92929D"
              className="group-hover:fill-success"
            />
          </svg>

          <h5 className="ml-3 text-[14px] font-medium text-black leading-5 font-figtree group-hover:text-success group-hover:font-bold">
            Applications
          </h5>
        </div>
      </NavLink>
      {/* Nav Item Upload Documents */}
      <NavLink to="/upload-documents" activeclassname="navbar__link--active">
        <div className="flex items-center py-3 group cursor-pointer">
          <BsFillCloudUploadFill
            size={20}
            className="text-[#92929D] group-hover:text-success"
          />
          <h5 className="ml-3 text-[14px] font-medium text-black leading-5 font-figtree group-hover:text-success group-hover:font-bold">
            Upload Documents
          </h5>
        </div>
      </NavLink>
    </div>
  );
};

export default UserLinks;
