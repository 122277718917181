// Required Docs Options List
export const documentsOptionList = [
  { value: 'Matriculation', label: 'Matriculation Mark sheet & Certificate' },
  { value: 'Intermediate', label: 'Intermediate Mark sheet & Certificate' },
  {
    value: 'OLevels',
    label: 'O Levels Levels Certificate',
  },
  {
    value: 'ALevels',
    label: 'A Levels Certificate',
  },
  { value: 'BachelDegreeTranscript', label: 'Bachelor Degree & Transcript' },
  { value: 'MasterDegreeTranscript', label: 'Master Degree & Transcript' },

  {
    value: 'StatementOfPurpose',
    label: 'Statement of Purpose – 700 to 800 words (Important for Admissions)',
  },
  { value: 'ReferenceLetters', label: 'Two Reference Letters' },
  { value: 'RecommendationLetters', label: 'Two Recommendation Letters' },

  { value: 'ExperienceCertificate', label: 'Experience Certificate(If any)' },
  { value: 'EmployerReference', label: 'Employer Reference (If any)' },

  { value: 'IELTSTOEFLPTE', label: 'IELTS/TOEFL/PTE' },
  { value: 'CurriculumVitae', label: 'Curriculum Vitae' },
  { value: 'PassportScan', label: 'Passport Scan (First 2 Pages)' },
  {
    value: 'AccountMaintenanceLetterBankStatement',
    label: 'Account Maintenance Letter and Bank Statement',
  },
  { value: 'UKTBMedicalTest', label: 'UKTB (Medical Test)' },
];
