import React from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AuthLayout from 'layout/AuthLayout';
import AuthenticatedRoutes from 'routes/AuthenticatedRoutes';

const App = () => {
  const user = useSelector((state) => state.auth.user?.access_token);

  return (
    <div>
      {user ? <AuthenticatedRoutes /> : <AuthLayout />}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default App;
