import axios from "axios";
import { toast } from "react-toastify";

import * as ActionTypes from "redux/actions//ActionTypes/ActionTypes";
import { setLoadingFalse, setLoadingTrue } from "./LoadingActions";
import { config } from "helpers/config/Config";
import { logoutUser } from "./AuthActions";

let backendUrl = `${process.env.REACT_APP_BACKEND_URL}`;

// Get User Info
export const getUserInfo = (token) => async (dispatch) => {
  try {
    dispatch(setLoadingTrue());
    const response = await axios.get(
      `${backendUrl}/user-info/retrieve`,
      config(token)
    );
    dispatch({
      type: ActionTypes.GET_USER_INFO,
      payload: response?.data,
    });
    if (response?.data?.userInfo[0]?.profile_picture) {
      dispatch(
        getUserProfleImg(response?.data?.userInfo[0]?.profile_picture, token)
      );
    } else {
      dispatch({
        type: ActionTypes.EMPTY_PROFILE_IMG,
      });
    }

    dispatch(setLoadingFalse());
  } catch (error) {
    console.log(error);
    dispatch(setLoadingFalse());
    toast.error(error?.response?.data?.message || error?.message);
  }
};
// Get User Info
export const getUserProfleImg = (documentData, token) => async (dispatch) => {
  try {
    let payloadDat = {
      document: documentData,
    };
    dispatch(setLoadingTrue());
    const response = await axios.post(
      `${backendUrl}/get-document`,
      payloadDat,
      config(token)
    );
    dispatch({
      type: ActionTypes.USER_PROFILE_IMG,
      payload: response?.data,
    });
    dispatch(setLoadingFalse());
  } catch (error) {
    console.log(error);
    dispatch(setLoadingFalse());
    toast.error(error?.response?.data?.message || error?.message);
  }
};

// Add User Info
export const addUserInfo = (formData, token) => async (dispatch) => {
  try {
    dispatch(setLoadingTrue());
    const response = await axios.post(
      `${backendUrl}/user-info/store`,
      formData,
      config(token)
    );
    dispatch(setLoadingFalse());
    dispatch(getUserInfo(token));
    toast.success(response?.data?.message);
  } catch (error) {
    console.log(error);
    dispatch(setLoadingFalse());
    toast.error(error?.response?.data?.message || error?.message);
  }
};

// Remove User Profile Image
export const removeUserProfileImg = (token, setFile) => async (dispatch) => {
  try {
    dispatch(setLoadingTrue());
    const response = await axios.get(
      `${backendUrl}/user-info/remove-profile`,
      config(token)
    );
    dispatch(setLoadingFalse());
    dispatch(getUserInfo(token));
    toast.success(response?.data?.message);
    setFile(null);
  } catch (error) {
    console.log(error);
    dispatch(setLoadingFalse());
    toast.error(error?.response?.data?.message || error?.message);
  }
};

// Get User Appointments
export const getAppointments = (page, token) => async (dispatch) => {
  try {
    dispatch(setLoadingTrue());
    let response = await axios.get(
      `${backendUrl}/user-appoint/viewapp?page=${page}`,
      config(token)
    );
    dispatch({
      type: ActionTypes.GET_USER_APPOINTMENTS,
      payload: response?.data,
    });
    dispatch(setLoadingFalse());
  } catch (error) {
    console.log(error);
    dispatch(setLoadingFalse());
    toast.error(error?.response?.data?.message || error.message);
  }
};

// Add Oppointment
export const addAppointment = (formData, page, token) => async (dispatch) => {
  try {
    dispatch(setLoadingTrue());
    let response = await axios.post(
      `${backendUrl}/user-appoint/requestapp`,
      formData,
      config(token)
    );
    toast.success(response?.data?.message);
    dispatch(setLoadingFalse());
    dispatch(getAppointments(page, token));
  } catch (error) {
    console.log(error);
    dispatch(setLoadingFalse());
    toast.error(error?.response?.data?.message || error?.message);
  }
};

// Get User Documents
export const getUserdocuments = (token) => async (dispatch) => {
  try {
    dispatch(setLoadingTrue());
    let response = await axios.get(
      `${backendUrl}/user-doc/retrieve`,
      config(token)
    );
    dispatch({
      type: ActionTypes.GET_USER_DOCUMENTS,
      payload: response?.data,
    });
    dispatch(setLoadingFalse());
  } catch (error) {
    console.log(error.message);
    dispatch(setLoadingFalse());
    toast.error(error?.response?.data?.message || error?.message);
  }
};

// Add User Document
export const addUserDocument =
  (formData, setActiveTab, current, token) => async (dispatch) => {
    try {
      dispatch(setLoadingTrue());
      let response = await axios.post(
        `${backendUrl}/user-doc/upload`,
        formData,
        config(token)
      );
      toast.success(response?.data?.message);
      dispatch(setLoadingFalse());
      setActiveTab(current + 1);
      dispatch(getUserdocuments(token));
    } catch (error) {
      console.log(error);
      dispatch(setLoadingFalse());
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

// Delete User Documents
export const deleteUserdocuments = (id, token) => async (dispatch) => {
  try {
    dispatch(setLoadingTrue());
    let response = await axios.delete(
      `${backendUrl}/user-doc/${id}`,
      config(token)
    );
    dispatch(getUserdocuments(token));
    dispatch(setLoadingFalse());
    toast.success(response?.data?.original?.message);
  } catch (error) {
    console.log(error);
    dispatch(setLoadingFalse());
    toast.error(error?.response?.data?.message || error?.message);
  }
};

// Delete User Appointment
export const deleteUserAppointment = (id, page, token) => async (dispatch) => {
  try {
    dispatch(setLoadingTrue());
    let response = await axios.delete(
      `${backendUrl}/user-appoint/${id}`,
      config(token)
    );
    dispatch(getAppointments(page, token));
    dispatch(setLoadingFalse());
    toast.success(response?.data?.original?.message);
  } catch (error) {
    console.log(error);
    dispatch(setLoadingFalse());
    toast.error(error?.response?.data?.message || error?.message);
  }
};

// Get User Applications
export const getApplications = (userId, page, token) => async (dispatch) => {
  try {
    dispatch(setLoadingTrue());
    let response = await axios.get(
      `${backendUrl}/user/get-admission-details/${userId}?page=${page}`,
      config(token)
    );
    dispatch({
      type: ActionTypes.GET_USER_APPLICATIONS,
      payload: response?.data,
    });
    dispatch(setLoadingFalse());
  } catch (error) {
    console.log(error);
    dispatch(setLoadingFalse());
    toast.error(error?.response?.data?.message || error.message);
  }
};

// Check User Session
export const checkSession = (token) => async (dispatch) => {
  try {
    let response = await axios.post(
      `${backendUrl}/session-check`,
      null,
      config(token)
    );
    if (response?.data?.data?.session_expired) {
      dispatch(logoutUser());
      toast.error("Session Expired!");
    }
  } catch (error) {
    console.log(error);
    // toast.error(error?.response?.data?.message || error.message);
  }
};
