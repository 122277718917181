import Spinner from 'components/spinner/Spinner';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';

const Messages = ({ messages, loading }) => {
  const userId = useSelector((state) => state?.user?.user_info?.user[0]?.id);
  const ownerId = userId ? userId : 1;

  if (loading) {
    return (
      <div className="flex flex-col justify-center items-center h-full">
        <Spinner />;
      </div>
    );
  }

  return (
    <div className="h-full">
      {messages.length ? (
        messages?.map((data, index) => (
          <div
            key={index}
            className={`px-2 my-2 flex flex-col ${
              ownerId == data.user_id
                ? 'items-end justify-end'
                : 'items-start justify-start'
            }`}
          >
            <p
              className={`text-[14px] md:text-[16px] w-fit break-all px-2 py-1 rounded-full text-white font-figtree font-normal ${
                ownerId == data.user_id ? 'bg-success' : 'bg-gray'
              }`}
            >
              {data?.content}
            </p>
            <p className="text-[10px] font-figtree font-medium text-dark">
              {moment(data?.created_at).format('hh:mm a')}
            </p>
          </div>
        ))
      ) : (
        <div className="flex justify-center items-center w-full h-full flex-col">
          <p className="text-center text-[14px]">
            Send a message to start a conversation
          </p>
        </div>
      )}
    </div>
  );
};

export default Messages;
