import axios from 'axios';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { RiCloseCircleFill } from 'react-icons/ri';
import { useSelector } from 'react-redux';

import classes from './Modal.module.css';
import { config } from 'helpers/config/Config';
import AnimatedLoading from 'components/spinner/AnimatedLoading';

export const TheCalenderAppointmentModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [appointmentData, setAppointmentData] = useState(null);

  const token = useSelector((state) => state?.auth?.user?.access_token);

  // Function To Get Appointment Details
  const getAppointmentDetails = async (id) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/single-appointment/${id}`,
        config(token)
      );
      setAppointmentData(response?.data[0]);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // Get Appointment Details
  useEffect(() => {
    getAppointmentDetails(props?.id);
  }, [props?.id]);

  return (
    <div>
      <div className={classes.backdrop}>
        <div
          className={`${classes.modal} ${classes.card} h-auto md:w-[600px] `}
        >
          <div className={`h-[auto] `}>
            <header className="bg-success p-2 flex items-center justify-between">
              <h3 className=" text-[14px] lg:text-[20px] font-figtree leading-8 font-medium text-white ">
                Appointment Details
              </h3>
              <RiCloseCircleFill
                size={20}
                className="text-white cursor-pointer"
                onClick={props.onConfirm}
              />
            </header>
            <div className="py-4">
              {loading ? (
                <div className="flex items-center justify-center">
                  <AnimatedLoading />
                </div>
              ) : (
                <div>
                  <table className="divide-y divide-[#E2E2EA] w-full">
                    <tr>
                      <th
                        scope="col"
                        className="flex w-max px-2 py-2 text-gray text-[16px] font-figtree font-medium leading-5"
                      >
                        User Name:
                      </th>
                      <td className=" p-2 text-dark capitalize text-[16px] leading-5 font-figtree font-normal">
                        {appointmentData?.user?.name}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="col"
                        className="flex w-max px-2 py-2 text-gray text-[16px] font-figtree font-medium leading-5"
                      >
                        Consultant Name:
                      </th>
                      <td className=" p-2 text-dark text-[16px] leading-5 font-figtree font-normal">
                        {appointmentData?.consultant_name}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="col"
                        className="flex w-max px-2 py-2 text-gray text-[16px] font-figtree font-medium leading-5"
                      >
                        Consultancy Agenda:
                      </th>
                      <td className="break-all p-2 text-dark text-[16px] leading-5 font-figtree font-normal">
                        {appointmentData?.agenda}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="col"
                        className="flex w-max px-2 py-2 text-gray text-[16px] font-figtree font-medium leading-5"
                      >
                        Date:
                      </th>
                      <td className=" p-2 text-dark text-[16px] leading-5 font-figtree font-normal">
                        {moment(appointmentData?.date).format('MMMM DD, YYYY')}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="col"
                        className="flex w-max px-2 py-2 text-gray text-[16px] font-figtree font-medium leading-5"
                      >
                        Time:
                      </th>
                      <td className=" p-2 text-dark text-[16px] leading-5 font-figtree font-normal">
                        {moment(appointmentData?.time, 'HH:mm:ss').format(
                          'hh:mm A'
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="col"
                        className="flex w-max px-2 py-2 text-gray text-[16px] font-figtree font-medium leading-5"
                      >
                        Status:
                      </th>
                      <td className=" p-2 text-dark text-[16px] leading-5 font-figtree font-normal">
                        {appointmentData?.status ? (
                          <p className="bg-successLight w-fit text-success text-[12px] font-medium font-figtree text-center py-1 px-4 rounded">
                            Approved
                          </p>
                        ) : (
                          <p className="bg-red-100 text-red-600 w-fit text-[12px] font-medium font-figtree text-center py-1 px-4 rounded">
                            Pending
                          </p>
                        )}
                      </td>
                    </tr>
                  </table>
                  {appointmentData?.status == 1 &&
                    appointmentData?.join_url && (
                      <div className="mx-auto block border-t border-t-[#E2E2EA]">
                        <button
                          className="relative mt-4 mx-auto block w-[150px] h-[30px] md:h-[30px] bg-success rounded-[3px] text-white font-figtree font-medium text-centerrelative  items-center justify-start  text-[12px]  text-center  px-2  boder-0 outline-none  overflow-hidden  transition-all   hover:bg-white hover:border border-success group"
                          onClick={() => window.open(appointmentData?.join_url)}
                        >
                          <span className="absolute inset-0 border-0 group-hover:border-[25px] ease-linear duration-100 transition-all border-white rounded-[6px]"></span>
                          <span className="relative w-full text-center text-white  transition-colors duration-200 ease-in-out group-hover:text-success">
                            Launch Meeting
                          </span>
                        </button>
                      </div>
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CalenderAppointmentModal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <TheCalenderAppointmentModal
          onConfirm={props.onConfirm}
          id={props?.id}
        />,
        document.getElementById('modal')
      )}
    </Fragment>
  );
};
