import React from 'react';

const DocumentsHeader = ({ title, text }) => {
  return (
    <div className=" h-auto md:h-[60px] border-b border-lightgray flex flex-col justify-center">
      <h3 className="text-[14px] pb-2 md:pb-0 font-normal md:text-[18px] text-blueDacker md:font-medium font-figtree">
        {title}
      </h3>
      <p className="text-dark font-normal font-figtree text-[13px] md:text-[14px]">
        {text}
      </p>
    </div>
  );
};

export default DocumentsHeader;
