import DetectClickOutside from 'modules/DetectClickOutside';
import { Fragment, useRef } from 'react';
import ReactDOM from 'react-dom';
import { RiCloseCircleFill } from 'react-icons/ri';
import { BsFillCheckCircleFill } from 'react-icons/bs';

import classes from './Modal.module.css';

export const TheRequiredDocsViewModal = (props) => {
  const clickRef = useRef();
  let missing_docs = Object.values(props?.documents?.missing_docs);

  return (
    <div>
      <DetectClickOutside clickRef={clickRef} onConfirm={props?.onConfirm} />
      <div className={classes.backdrop}>
        <div
          className={`${classes.modal} ${classes.card} h-auto w-[95%] md:w-[400px]`}
          ref={clickRef}
        >
          <div className="py-2 flex justify-between items-center px-2 border-b border-b-lightgray">
            <h5 className="text-red-500 text-center text-[18px] font-figtree font-medium">
              Required Documents
            </h5>
            <RiCloseCircleFill
              size={20}
              className="text-red-500 cursor-pointer"
              onClick={props.onConfirm}
            />
          </div>
          <div
            className={`h-[auto] max-h-[400px] overflow-auto custom-scroll-bar`}
          >
            {Array.isArray(props?.documents?.required_docs) &&
              props?.documents?.required_docs?.map((data, index) => (
                <div
                  key={index}
                  className="border-b border-b-lightgray flex items-center"
                >
                  <p className="p-2 text-[15px] font-figtree font-normal">
                    {index + 1} &nbsp;
                    {data?.value}
                  </p>

                  {Array.isArray(missing_docs) &&
                  missing_docs.includes(data.value) ? (
                    <p className="text-red-500 text-[14px] font-figtree font-normal">
                      (Pending)
                    </p>
                  ) : (
                    <p className="text-success text-[14px] font-figtree font-normal">
                      (Uploaded)
                    </p>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export const RequiredDocsViewModal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <TheRequiredDocsViewModal
          onConfirm={props.onConfirm}
          documents={props?.documents}
        />,
        document.getElementById('modal')
      )}
    </Fragment>
  );
};
