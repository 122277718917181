import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import TextField from 'components/textfield/TextField';
import Spinner from 'components/spinner/Spinner';
import { changePassword } from 'redux/actions/ActionCreators/AuthActions';

const ChangePassword = () => {
  const loading = useSelector((state) => state?.auth?.loading);
  const token = useSelector((state) => state?.auth?.user?.access_token);
  const dispatch = useDispatch();

  // Form Initial Values
  const initialValues = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };
  // Error Schema
  const errorSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Old Password is required'),
    newPassword: Yup.string().required('New Password is required'),
    confirmNewPassword: Yup.string().oneOf(
      [Yup.ref('newPassword'), null],
      'Passwords must match'
    ),
  });
  // Form Submission
  const handleSubmit = (values, { resetForm }) => {
    let data = {
      old_password: values.oldPassword,
      new_password: values.newPassword,
    };
    dispatch(changePassword(data, token));
    resetForm({
      values: '',
    });
  };
  return (
    <div>
      <h3 className=" py-6 text-center text-[20px] capitalize lg:text-[28px] font-figtree leading-8 font-bold text-black 2xl:w-[1350px] 2xl:mx-auto">
        Change Password
      </h3>
      <div className="rounded-[12px] transition bg-[#ffffff] px-6  md:px-6 lg:px-8 py-6  lg:w-[50%] 2xl:w-[550px] lg:mx-auto">
        <Formik
          initialValues={initialValues}
          validationSchema={errorSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form className="w-full 2xl:flex 2xl:flex-col 2xl:justify-center 2xl:items-center transition">
              <TextField
                type="password"
                label="Old Password"
                name="oldPassword"
                placeholder="Enter your old password"
              />
              <TextField
                type="password"
                label="New Password"
                name="newPassword"
                placeholder="Enter your new password"
              />
              <TextField
                type="password"
                label="Confirm New Password"
                name="confirmNewPassword"
                placeholder="Confirm your new password"
              />
              {/* Sign In Button  */}
              <button className="relative  inline-flex items-center justify-start inline-block  text-white text-[14px] font-jakarta-sans font-medium w-full xl:w-full 2xl:w-[436px] h-[44px] text-center  px-4    boder-0 outline-none  overflow-hidden  transition-all bg-success rounded-[6px] hover:bg-white hover:border border-success group">
                <span className="absolute inset-0 border-0 group-hover:border-[25px] ease-linear duration-100 transition-all border-white rounded-[6px]"></span>
                <span className="relative w-full text-center text-white transition-colors duration-200 ease-in-out group-hover:text-success">
                  {loading ? <Spinner /> : 'Submit'}
                </span>
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ChangePassword;
