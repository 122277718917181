import React from 'react';
import UnAuthenticatedRoutes from 'routes/UnAuthenticatedRoutes';

const AuthLayout = () => {
  return (
    <div className="auth-bg relative flex justify-center items-center transition">
      <div className="blue-bg  lg:w-[50%] z-0 transition"></div>
      <div className="lg:grid lg:grid-cols-12 flex flex-col-reverse w-[90%] md:w-[70%] py-8 lg:py-0 lg:w-full transition">
        <div className=" lg:col-span-4"></div>
        <div className=" lg:col-span-4 z-10 md:w-[90%] md:m-auto lg:w-full transition 2xl:flex 2xl:justify-center ">
          <UnAuthenticatedRoutes />
        </div>
        <div className=" lg:col-span-4 flex justify-center items-center z-10 transition">
          <h3 className="text-ligth text-center lg:text-left  font-jakarta-sans text-[20px] font-bold  mb-4 lg:mb-0 lg:text-[37px] lg:px-3 xl:px-4 2xl:px-0 xl:text-[43px] 2xl:text-[48px] line-height-[52px] ">
            Your higher education journey starts with us, right here!
          </h3>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
