import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AppointmentsTable from 'components/dashboard/AppointmentsTable';
import { AppointmentModal } from 'components/modal/AppointmentModal';
import NoRecord from 'components/norecord/NoRecord';
import AppointmentIcon from 'assets/images/appoint-icon.png';
import Pagination from 'modules/Pagination';
import { getAppointments } from 'redux/actions/ActionCreators/UserActions';
import AnimatedLoading from 'components/spinner/AnimatedLoading';

const AppointmentList = () => {
  const dispatch = useDispatch();
  const appointments = useSelector((state) => state?.user?.appointments);
  const token = useSelector((state) => state?.auth?.user?.access_token);
  const loading = useSelector((state) => state?.user?.loading);
  const [appointmentModal, setAppointmentModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const handleConfirmModal = () => {
    setAppointmentModal(false);
  };

  useEffect(() => {
    dispatch(getAppointments(currentPage + 1, token));
  }, [currentPage]);

  // Set Cuurent Page Number to current - 1 if Next page url is null and appointments lenght == 10
  useEffect(() => {
    if (
      appointments?.data?.length == 10 &&
      !appointments?.next_page_url &&
      currentPage !== 0
    ) {
      setCurrentPage(currentPage - 1);
    }
  }, [appointments?.data?.length]);

  // Loading
  if (loading) {
    return (
      <div className="flex items-center justify-center h-[calc(100vh-90px)]">
        <AnimatedLoading />
      </div>
    );
  }

  return (
    <div className="min-h-full">
      <h3 className=" py-6 text-[20px] lg:text-[28px] font-figtree leading-8 font-bold text-black 2xl:w-[1300px] 2xl:mx-auto">
        Appointments List
      </h3>
      <div className="bg-white transition rounded-[12px] w-full md:px-4 lg:px-6 py-4 flex  flex-col  2xl:w-[1300px] 2xl:mx-auto ">
        <div className="flex items-center px-2 md:px-0">
          <img
            src={AppointmentIcon}
            loading="lazy"
            alt="icon"
            className="h-[16px] w-[16px] mr-3"
          />
          <h5 className="text-[16px] leading-6 font-figtree font-medium text-blueDacker">
            Appointments
          </h5>
        </div>
        {appointments?.data?.length ? (
          <div className=" grid grid-cols-1  pt-4">
            <AppointmentsTable
              appointmentsData={appointments?.data}
              page={currentPage + 1}
            />
            {appointments?.total > 10 && (
              <Pagination
                itemsPerPage={appointments?.per_page}
                totalPages={appointments?.total / 10}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            )}
          </div>
        ) : (
          <div className="text-center flex items-center flex-col h-full justify-center text-dark">
            <NoRecord
              title={`Sorry, we couldn't find any appointments data.`}
            />
          </div>
        )}
        {appointmentModal && (
          <AppointmentModal
            onConfirm={handleConfirmModal}
            page={currentPage + 1}
          />
        )}
        <div className="flex justify-end items-center">
          <button
            className="relative mx-auto lg:mx-0 mt-3 w-[150px] h-[36px] md:h-[44px] bg-success rounded-[3px] text-white font-figtree font-medium text-centerrelative  inline-flex items-center justify-start inline-block text-[14px]  text-center  px-4  boder-0 outline-none  overflow-hidden  transition-all   hover:bg-white hover:border border-success group"
            onClick={() => setAppointmentModal(true)}
          >
            <span className="absolute inset-0 border-0 group-hover:border-[25px] ease-linear duration-100 transition-all border-white rounded-[6px]"></span>
            <span className="relative w-full text-center text-white transition-colors duration-200 ease-in-out group-hover:text-success">
              Add Appointment
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AppointmentList;
