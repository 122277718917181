import React from 'react';
import { ErrorMessage, useField } from 'formik';
import Select from 'react-select';

const SelectTextField = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: '#E8EDF1',
      height: '42px',
      border: '0',
      '@media only screen and (max-width: 767px)': {
        ...styles['@media only screen and (max-width: 767px)'],
        height: '34px',
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? '#00A58E' : isFocused ? '#ECF8F7' : '',
        cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },
  };

  return (
    <div className="form-group  md:my-3 my-4 lg:my-4 xl:my-6">
      {label && (
        <label
          htmlFor={field.name}
          className="block text-dark text-[14px] leading-[20px] font-figtree font-bold"
        >
          {label}
        </label>
      )}
      <Select
        styles={colourStyles}
        className={`w-full xl:w-full 2xl:w-[436px]  md:h-[44px] rounded-[6px]  mt-2  bg-lightgray focus:ring-blue-500 focus:border-blue-500 focus:outline-none     dark:focus:ring-blue-500 dark:focus:border-blue-500 ${
          meta.touched && meta.error && 'border border-red-500 error-form'
        }`}
        {...field}
        {...props}
      />
      <ErrorMessage
        component="p"
        name={field.name}
        className="text-red-500 font-normal font-figtree text-sm mt-2"
      />
    </div>
  );
};

export default SelectTextField;
