import { Fragment, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { RiCloseCircleFill } from 'react-icons/ri';

import classes from './Modal.module.css';
import AppointmentForm from 'components/appointments/AppointmentForm';
import DetectClickOutside from 'modules/DetectClickOutside';

export const TheAppointmentModal = (props) => {
  const clickRef = useRef('');

  return (
    <div>
      <div className={classes.backdrop}>
        <div className={`${classes.modal} ${classes.card}`} ref={clickRef}>
          {/* // Detect Click Outside the Modal */}
          <DetectClickOutside clickRef={clickRef} onConfirm={props.onConfirm} />
          <header
            className={`${classes.header} flex items-center justify-between `}
          >
            <h3 className=" text-[16px] lg:text-[24px] font-figtree leading-8 font-medium text-white ">
              {props?.id ? ' Edit Appointment' : ' Add Appointment'}
            </h3>
            <RiCloseCircleFill
              size={25}
              className="text-white cursor-pointer"
              onClick={props.onConfirm}
            />
          </header>
          <div
            className={`${classes.content} max-h-[500px] overflow-y-auto  md:max-h-[600px]`}
          >
            <AppointmentForm
              id={props?.id}
              onConfirm={props.onConfirm}
              page={props?.page}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const AppointmentModal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <TheAppointmentModal
          onConfirm={props.onConfirm}
          id={props?.id}
          page={props?.page}
        />,
        document.getElementById('modal')
      )}
    </Fragment>
  );
};
