import moment from 'moment';
import React from 'react';
import { BsChatDots } from 'react-icons/bs';

import Profile from 'assets/images/user.png';
import NoRecord from 'components/norecord/NoRecord';

const TodaysMeetingCard = ({ todaysAppointmentData }) => {
  return (
    <div className="transition px-4 py-6 flex flex-col w-full bg-success  2xl:w-[376px] lg:h-[350px] rounded-[12px]">
      {todaysAppointmentData ? (
        <div>
          <div className="flex items-center border-b border-b-lightgray pb-3 ">
            <img
              src={Profile}
              alt="user-img"
              loading="lazy"
              className="h-[72px] w-[72px] rounded-full"
            />
            <div className="ml-3">
              <h3 className="text-white font-figtree font-medium text-[22px] leading-6">
                {todaysAppointmentData?.consultant_name}
              </h3>
              <p className="text-white text-[12px] font-normal font-figtree">
                Professional Educational Consultant
              </p>
            </div>
          </div>
          <div className=" border-b border-b-lightgray py-4">
            <p className="text-white text-[14px] font-normal font-figtree">
              Consultancy Agenda:
            </p>
            <h3 className="text-white text-[18px] font-medium leading-6 font-figtree">
              {todaysAppointmentData?.agenda}
            </h3>
          </div>
          <div className="flex justify-between py-4 lg:py-2 xl:py-4">
            <div>
              <p className="text-white text-[14px] font-normal font-figtree">
                Date:
              </p>
              <h3 className="text-white text-[18px] font-medium leading-6 font-figtree">
                {moment(todaysAppointmentData?.date).format('MMMM DD, YYYY')}
              </h3>
            </div>
            <div>
              <p className="text-white text-[14px] font-normal font-figtree">
                Time:
              </p>
              <h3 className="text-white text-[18px] font-medium leading-6 font-figtree">
                {moment(todaysAppointmentData?.time, 'HH:mm:ss').format(
                  'hh:mm A'
                )}
              </h3>
            </div>
          </div>
          <div className="flex items-center justify-between xl:pb-2">
            {todaysAppointmentData?.status == 0 && (
              <button
                className="h-[30px] border-0 outline-none w-[150px] xl:h-[44px] xl:w-[200px] bg-lightgray rounded-[3px] text-dark font-figtree font-medium"
                disabled={!todaysAppointmentData?.join_url}
              >
                Pending
              </button>
            )}
            {todaysAppointmentData?.status == 1 &&
              !todaysAppointmentData?.join_url && (
                <button
                  className="h-[30px] border-0 outline-none w-[150px] xl:h-[44px] xl:w-[200px] bg-lightgray rounded-[3px] text-dark font-figtree font-medium"
                  disabled={!todaysAppointmentData?.join_url}
                >
                  Approved
                </button>
              )}
            {todaysAppointmentData?.join_url && (
              <button
                className="h-[30px] border-0 outline-none w-[150px] xl:h-[44px] xl:w-[200px] bg-lightgray rounded-[3px] text-dark font-figtree font-medium"
                disabled={!todaysAppointmentData?.join_url}
                onClick={() => window.open(todaysAppointmentData?.join_url)}
              >
                Join Meeting
              </button>
            )}
          </div>
        </div>
      ) : (
        <NoRecord title={'No Appointment For Today'} />
      )}
    </div>
  );
};

export default TodaysMeetingCard;
