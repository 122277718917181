import React from 'react';
import { AiOutlineSearch } from 'react-icons/ai';

const Search = ({ searchQuery, setSearchQuery, handleSearch }) => {
  return (
    <div>
      <form
        onSubmit={handleSearch}
        className="flex  lg:flex-row items-center  mt-6"
      >
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="search"
          className="w-full lg:my-0  h-[36px] md:h-[36px] rounded-[3px]  p-3 bg-lightgray focus:ring-blue-500 focus:border-blue-500 focus:outline-none  dark:focus:ring-blue-500 dark:focus:border-blue-500 "
        />
        <div className="flex justify-center lg:justify-start ">
          <button
            type="submit"
            className="relative  px-3 mx-3 h-[36px] md:h-[36px] bg-success rounded-[3px]  "
          >
            <AiOutlineSearch size={25} className="text-white" />
          </button>
        </div>
      </form>
    </div>
  );
};

export default Search;
