import { Fragment, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { RiCloseCircleFill } from 'react-icons/ri';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useSelector } from 'react-redux';

import classes from './Modal.module.css';
import ProfileField from 'components/textfield/ProfileField';
import Spinner from 'components/spinner/Spinner';
import SelectTextField from 'components/textfield/SelectField';
import DetectClickOutside from 'modules/DetectClickOutside';
import { config } from 'helpers/config/Config';
import MultiSelectTextField from 'components/textfield/MultiSelectField';
import { documentsOptionList } from 'modules/DocumentOptionsList';

export const TheAddApplicationFromStudentDetails = (props) => {
  const clickRef = useRef('');
  const token = useSelector((state) => state.auth.user.access_token);
  const { userName, id, renderStudentDeatils } = props;

  const [loading, setLoading] = useState(false);
  const [selectRequiredDocuments, setRequiredDocument] = useState(['']);
  const [applicationStatus, setApplicationStatus] = useState({
    label: 'Select Status',
    value: '',
  });
  // Status Options List
  const statusOptions = [
    {
      value: 0,
      label: 'Pending',
    },
    {
      value: 1,
      label: 'Processing',
    },
    {
      value: 2,
      label: 'Rejected',
    },
  ];

  //Form Initial Values
  let initialValues = {
    university_name: '',
    course_name: '',
    required_docs: [],
    status: '',
  };

  // Error Schema
  const errorSchema = Yup.object().shape({
    university_name: Yup.string().required('University Name is required'),
    required_docs: Yup.array().min(1).required('Select required documents'),
    course_name: Yup.string().required('Course Name is required'),
  });

  // Form Submission
  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true);
      // Payload Data
      let data = {
        user_id: id,
        applied_university: values?.university_name,
        course_name: values?.course_name,
        requiredDocuments: JSON.stringify(values?.required_docs),
        status: values.status,
      };
      let response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/apply-for-admission`,
        data,
        config(token)
      );
      toast.success(response?.data?.original?.message);
      props.onConfirm();
      renderStudentDeatils(true);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  return (
    <div>
      <div className={classes.backdrop}>
        <div className={`${classes.modal} ${classes.card}`} ref={clickRef}>
          {/* // Detect Click Outside the Modal */}
          <DetectClickOutside clickRef={clickRef} onConfirm={props.onConfirm} />
          <header
            className={`${classes.header} flex items-center justify-between `}
          >
            <h3 className=" text-[16px] lg:text-[24px] font-figtree leading-8 font-medium text-white ">
              Add Application
            </h3>
            <RiCloseCircleFill
              size={25}
              className="text-white cursor-pointer"
              onClick={props.onConfirm}
            />
          </header>
          <div
            className={`${classes.content} h-[500px] overflow-y-auto md:overflow-hidden md:h-full`}
          >
            <div className="bg-white transition rounded-[12px] w-full flex  flex-col lg:flex-row  ">
              <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={errorSchema}
                onSubmit={handleSubmit}
              >
                {(props) => (
                  <Form className="w-full">
                    <MultiSelectTextField
                      label="Select Required Documents"
                      name="required_docs"
                      placeholder="Select require documents"
                      value={selectRequiredDocuments}
                      options={documentsOptionList}
                      isMulti
                      onChange={(e) => {
                        props.setFieldValue(
                          'required_docs',
                          e?.map((data) => data?.value)
                        );
                        setRequiredDocument(e);
                      }}
                    />
                    <div className="w-full grid grid-cols-1 md:grid-cols-2 md:gap-2">
                      <ProfileField
                        type="text"
                        label="User Name"
                        name="user"
                        disabled
                        value={userName}
                        placeholder="user name"
                      />
                      <SelectTextField
                        label="Select Status"
                        name="status"
                        value={applicationStatus}
                        options={statusOptions}
                        onChange={(e) => {
                          props.setFieldValue('status', e.value);
                          setApplicationStatus(e);
                        }}
                      />
                      <ProfileField
                        type="text"
                        label="University Name"
                        name="university_name"
                        placeholder="enter university name"
                      />
                      <ProfileField
                        type="text"
                        label="Course Name"
                        name="course_name"
                        placeholder="enter course name"
                      />
                    </div>
                    <button className="relative  mx-auto  mt-3 w-full xl:w-[230px] h-[36px] md:h-[44px] bg-success rounded-[3px] text-white font-figtree font-medium text-centerrelative   items-center justify-center block  text-[14px]  text-center  px-4  boder-0 outline-none  overflow-hidden  transition-all   hover:bg-white hover:border border-success group">
                      <span className="absolute inset-0 border-0 group-hover:border-[25px] ease-linear duration-100 transition-all border-white rounded-[6px]"></span>
                      <span className="relative w-full text-center text-white transition-colors duration-200 ease-in-out group-hover:text-success">
                        {loading ? <Spinner /> : 'Add Applicaton'}
                      </span>
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const AddApplicationFromStudentDetails = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <TheAddApplicationFromStudentDetails
          onConfirm={props.onConfirm}
          id={props?.id}
          userName={props?.userName}
          renderStudentDeatils={props?.renderStudentDeatils}
        />,
        document.getElementById('modal')
      )}
    </Fragment>
  );
};
