import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { BsFillSendFill } from 'react-icons/bs';
import { toast } from 'react-toastify';
import Messages from './Messages';
import { config } from 'helpers/config/Config';
import { useSelector } from 'react-redux';
import Spinner from 'components/spinner/Spinner';
import Pusher from 'pusher-js';

const AdminChatPreview = ({ userId }) => {
  const [loading, setLoading] = useState(false);
  const [messagesData, setMessagesData] = useState([]);
  const [messageText, setMessageText] = useState('');
  const token = useSelector((state) => state?.auth?.user?.access_token);
  const scrollRef = useRef();

  // Scroll Into bottom (View)
  useEffect(() => {
    scrollRef.current?.scrollTo(0, scrollRef.current?.scrollHeight);
  }, [messagesData]);

  // Function To Get Messages data
  const getMessages = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/chat/${userId}/messages`,
        config(token)
      );
      setMessagesData(response?.data?.chat_messages);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
  useEffect(() => {
    getMessages();
  }, [userId]);

  // Initialize Pusher-js
  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
      cluster: 'us2',
      encrypted: true,
    });
    // subscribe chat chanel
    const channel = pusher.subscribe('chat');
    // Binding chanel to pusher event and setting messages into state
    channel.bind('new-message', (data) => {
      setMessagesData((prv) => [...prv, data.message]);
    });
    setMessageText('');
    return () => {
      pusher.unsubscribe('chat');
    };
  }, []);

  // Handler For send message
  const sendMessage = async (e) => {
    e.preventDefault();
    if (messageText.trim()) {
      try {
        let data = {
          message: messageText,
          receiver_id: userId,
        };
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/admin/send-message`,
          data,
          config(token)
        );
        setMessageText('');
      } catch (error) {
        console.log(error);
      }
    }
  };
  // Loading
  if (loading) {
    return (
      <div className="col-span-7 md:col-span-8 lg:col-span-9 h-full w-full flex justify-center items-center ">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="col-span-7 md:col-span-8 lg:col-span-9 h-full overflow-hidden w-full relative">
      <div
        ref={scrollRef}
        className="h-[calc(100%-80px)]  overflow-y-auto custom-scroll-bar w-full"
      >
        <Messages messages={messagesData} />
      </div>
      <div className=" h-[80px] bg-lightgray w-full">
        <form className="w-full flex items-center" onSubmit={sendMessage}>
          <input
            className="bg-lightgray boder-0 outline-none px-2 py-2 w-full"
            placeholder="type message...."
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
          />
          <BsFillSendFill
            className="mr-4  text-success cursor-pointer"
            type="submit"
            size={20}
            onClick={sendMessage}
          />
        </form>
      </div>
    </div>
  );
};

export default AdminChatPreview;
