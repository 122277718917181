import React from 'react';
import ReactPaginate from 'react-paginate';

function Pagination({ totalPages, currentPage, setCurrentPage }) {
  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  return (
    <div className="w-full md:w-[80%] lg:w-[50%] mx-auto  bg-successLight rounded-lg py-3">
      <ReactPaginate
        breakLabel="..."
        nextLabel=" >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={4}
        pageCount={totalPages}
        initialPage={currentPage}
        previousLabel="<"
        renderOnZeroPageCount={null}
        className=" flex flex-row w-full items-center justify-center  text-base font-medium text-gray-500"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active bg-gray rounded-full"
      />
    </div>
  );
}

export default Pagination;
