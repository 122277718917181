import * as ActionTypes from '../actions/ActionTypes/ActionTypes'

let initialState = {
    user: null,
    isAuthenticated: false,
    error: null,
    loading: false
}

const AuthReducer = (state=initialState, action) => {
    switch (action.type) {
      case ActionTypes.REGISTER_USER: {
        return {
          ...state,
          user: action.payload,
          isAuthenticated: true,
        };
      }
      case ActionTypes.LOGIN_USER: {
        return {
          ...state,
          user: action.payload,
          isAuthenticated: true,
        };
      }
      case ActionTypes.LOGOUT_USER: {
        localStorage.clear()
        return {
          user: null,
          isAuthenticated: false,
          error: null,
          loading: false
        }
      }
      case ActionTypes.SET_ERROR: {
        return {
          ...state,
          user: null,
          isAuthenticated: false,
          error: action.payload,
        };
      }
      case ActionTypes.SET_LOADING_TRUE: {
        return {
          ...state,
          loading: true,
        };
      }
      case ActionTypes.SET_LOADING_FALSE: {
        return {
          ...state,
          loading: false,
        };
      }
      default: {
        return state;
      }
    }
}

export default AuthReducer