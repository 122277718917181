import React, { useEffect, useState } from 'react';
import { GoVerified } from 'react-icons/go';
import { CgCloseO } from 'react-icons/cg';
import { MdOutlineReadMore } from 'react-icons/md';
import { AiOutlineDelete } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

import { DeleteConfirmationModal } from 'components/modal/DeleteConfirmationModal';

const AllStudentsTable = ({ studentsData, page }) => {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [deleteModal, setDeleteModal] = useState(false);
  const [studentId, setStudentId] = useState(null);

  // Set Current Page
  useEffect(() => {
    if (page) {
      if (page == 1) {
        setCurrentPage(page);
      } else if (page > 1 && studentsData.length > 1) {
        setCurrentPage(page);
      } else {
        setCurrentPage(page - 1);
      }
    }
  }, [page, studentsData.length]);

  // Handler For Confirm Delete Student
  const handleDeleteStudent = (id) => {
    setStudentId(id);
    setDeleteModal(true);
  };

  // Handler on confrim modal close
  const handleConfirm = () => {
    setDeleteModal(false);
  };
  return (
    <div className="flex flex-col px-2">
      {/* Delete Student Confirmation modal */}
      {deleteModal && (
        <DeleteConfirmationModal
          onConfirm={handleConfirm}
          id={studentId}
          page={currentPage}
        />
      )}
      <div className="sm:-mx-6 lg:-mx-8">
        <div className=" min-w-full py-2  lg:px-4">
          <div className="overflow-x-auto custom-scroll-bar">
            <table className="min-w-full  text-left text-sm font-light">
              <thead className="bg-successLight font-medium ">
                <tr>
                  <th
                    scope="col"
                    className="px-6  py-4 text-success text-[14px] font-figtree font-bold leading-5"
                  >
                    Id
                  </th>
                  <th
                    scope="col"
                    className="px-6 flex w-max py-4 text-success text-[14px] font-figtree font-bold leading-5"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-4 text-success text-[14px] font-figtree font-bold leading-5"
                  >
                    Email
                  </th>
                  {/* <th
                    scope="col"
                    className="px-6 py-4 text-center text-success text-[14px] font-figtree font-bold leading-5"
                  >
                    Verified
                  </th> */}
                  <th
                    scope="col"
                    className="px-6 py-4 text-success text-[14px] font-figtree font-bold leading-5"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {studentsData.map((data, index) => (
                  <tr className="border-b border-[#E2E2EA]" key={index}>
                    <td className="whitespace-nowrap capitalize px-6 py-4 text-dark text-[14px] leading-5 font-figtree font-normal">
                      {data?.id}
                    </td>
                    <td className="whitespace-nowrap capitalize px-6 py-4 text-dark text-[14px] leading-5 font-figtree font-normal">
                      {data?.name}
                    </td>
                    <td className="whitespace-nowrap  px-6 py-4 text-dark text-[14px] leading-5 font-figtree font-normal">
                      {data?.email}
                    </td>
                    {/* <td className="whitespace-nowrap flex justify-center  px-6 py-4 leading-5 font-figtree font-normal">
                      {data?.email_verified_at ? (
                        <GoVerified size={20} className="text-success" />
                      ) : (
                        <CgCloseO size={20} className="text-red-500" />
                      )}
                    </td> */}

                    <td className="whitespace-nowrap   px-6 py-4 text-dark text-[14px] leading-5 font-figtree font-normal">
                      <div className="flex items-center">
                        <AiOutlineDelete
                          onClick={() => handleDeleteStudent(data?.id)}
                          size={20}
                          className="text-gray mr-2 cursor-pointer"
                        />
                        <MdOutlineReadMore
                          size={25}
                          className="text-primary  justify-center cursor-pointer"
                          onClick={() => navigate(`/students/${data?.id}`)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllStudentsTable;
